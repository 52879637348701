<template>
  <div class="teacher-lesson-wrap">
    <V2Wrapper>
      <div class="tlw-inner">
        <template v-if="this.isLoading">
          <div class="wi-loading">
            <b-spinner
                style="width: 3rem; height: 3rem;"
                variant="primary"
                label="Spinning"
            ></b-spinner>
          </div>
        </template>
        <template v-else>
          <div class="wi-body-wrap">
            <div :class="['side-nav-expand-btn', {'expand': this.isSideNavExpand}]"
                 @click="() => this.isSideNavExpand = true"
            >
              <img class="expand-arrow-btn" src="@/assets/images/arrow-right.png" alt="">
            </div>
            <div :class="['side-nav-wrap', {'expand': this.isSideNavExpand}]">
              <div class="sn-header-wrap"
                   @click="() => this.isSideNavExpand = false"
              >
                <p>공유된 페이지</p>
                <img class="expand-arrow-btn" src="@/assets/images/arrow-left.png" alt="">
              </div>
              <div class="sn-sub-header-wrap"
                   @click="() => this.onClickMenu(null)"
              >
                <p>📕 에스덕 온보딩 보드</p>
              </div>
              <div class="sn-body-wrap">
                <div class="menu-item-list">
                  <template v-if="this.sideNavMenuItems.length == 0">
                    <div class="menu-item"
                         @click="() => this.addMenu(null, null)"
                    >
                      <img class="li-plus-icon" src="@/assets/images/plus.png" alt="">
                      <p class="li-text">페이지 추가</p>
                    </div>
                  </template>
                  <template v-else>
                    <template v-for="(menuItem, index) in this.sideNavMenuItems" :key="index">
                      <div :class="['menu-item', `d-${menuItem.depth}`, {'active': menuItem.id === this.selectMenuId}]"
                           @click="() => this.onClickMenu(menuItem.id)"
                      >
                        <div class="li-menu-icon">
                          <img src="@/assets/images/file.png" alt=""/>
                          <img src="@/assets/images/arrow-right-7.png" alt=""/>
                        </div>
                        <p class="li-text">{{ menuItem.title }}</p>
                        <div class="li-hover-btn-list">
                          <button @click="() => this.showEditMenu = Object.assign({}, menuItem)"
                                  onclick="event.stopPropagation();"
                          >
                            <img src="@/assets/images/edit-2.png" alt="">
                          </button>
                          <button @click="() => this.deleteMenu(menuItem.id)"
                                  onclick="event.stopPropagation();"
                          >
                            <img src="@/assets/images/trash.png" alt="">
                          </button>
                          <button v-if="menuItem.depth < 2"
                                  @click="() => this.addMenu(menuItem.id, null)"
                                  onclick="event.stopPropagation();"
                          >
                            <img src="@/assets/images/plus-2.png" alt="">
                          </button>
                        </div>
                      </div>
                    </template>
                  </template>
                </div>
                <div class="user-item-list">
                  <template v-for="(userItem, index) in this.userItems" :key="index">
                    <div class="user-item">
                      <div class="liu-thumb">
                        <img :src="userItem.thumbnailUrl" alt=""/>
                      </div>
                      <p>{{ userItem.name }}</p>
                      <div :class="['liu-online', {'active': userItem.isOnline}]"></div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
            <div class="v2-base-container">
              <div :class="['bc-loading', {'active': this.isLoadingContents}]">
                <b-spinner
                    style="width: 3rem; height: 3rem;"
                    variant="primary"
                    label="Spinning"
                ></b-spinner>
              </div>
              <div :class="['bc-empty-text', {
                'active': this.isLoadingContents === false && this.selectMenuId === null,
              }]">페이지를 먼저 선택해주세요.</div>
              <div :class="['bc-empty-text', {
                'active': this.isLoadingContents === false && this.isContentsNotFound,
              }]">해당 메뉴를 찾을 수 없습니다.</div>
              <div :class="['bc-inner-wrap', {
                'active': this.isLoadingContents === false && this.selectMenuId !== null,
              }]">
                <div id="editorjs"
                     ref="editorJsWrap"
                ></div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </V2Wrapper>
  </div>

  <EditMenuPopup
    :edit-menu="this.showEditMenu"
    :on-close="() => this.showEditMenu = null"
    :on-change="this.onChangeBoardHeaderTitle"
  />
  <LoadingDialog
    :is-show="this.isLoadingDialog"
  />
</template>

<script>
import V2Wrapper from "@/components/V2Wrapper.vue";
import BoardService from "@/services/board.service";
import ProblemSuggestPopup from "@/components/ProblemSuggestPopup.vue";
import LoadingDialog from "@/components/LoadingDialog.vue";
import { debounce } from 'lodash'
import EditMenuPopup from "@/components/EditMenuPopup.vue";

export default {
  name: 'TeacherLesson',
  components: {
    EditMenuPopup,
    LoadingDialog,
    ProblemSuggestPopup,
    V2Wrapper,
  },
  watch: {
    $route (to, from) {
      const selectMenuId = parseInt(to.query.menuId ?? null);
      this.selectMenuId = isNaN(selectMenuId) ? null : selectMenuId;
    },
    async selectMenuId() {
      this.isLoadingContents = true;
      this.$refs.editorJsWrap.innerHTML = '';
      const selectMenuId = this.selectMenuId;

      console.log(`selectMenuId => ${selectMenuId}`);
      if (selectMenuId !== null) {
        const res = await BoardService.getBoardContents(selectMenuId);
        this.isContentsNotFound = !res.is_exist;
        if (res.is_exist) {
          const boardContentsId = res.id;
          const initContents = res.contents.length > 0 ? JSON.parse(res.contents) : {};

          const editor = new window.EditorJS({
            holder: 'editorjs',
            autofocus: true,
            minHeight: 30,
            inlineToolbar: [
              'link',
              'bold',
              'italic',
            ],
            data: initContents,
            placeholder: '여기 내용을 입력해주세요.',
            readOnly: false,
            onReady: () => {},
            onChange: async (api, event) => {
              const contents = await editor.save();
              const encodedContents = JSON.stringify(contents);
              await BoardService.editBoardContents(boardContentsId, encodedContents);
            },
            tools: {
              header: {
                class: window.Header,
                inlineToolbar: true,
              },
              embed: {
                class: window.Embed,
                inlineToolbar: true,
              },
              image: {
                class: window.InlineImage,
                inlineToolbar: true,
                config: {
                  embed: {
                    display: true,
                  },
                },
              },
            },
          });
        }
      }
      this.isLoadingContents = false;
    },
  },
  data() {
    const sampleNames = [
      '강기복',
      '도레미',
      '라분발',
    ];
    const sampleThumbnailUrls = [
      'https://images.unsplash.com/photo-1438761681033-6461ffad8d80?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
      'https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?q=80&w=2080&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
      'https://images.unsplash.com/photo-1520626337972-ebf863448db6?q=80&w=1925&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    ];

    return {
      isLoading: true,
      isLoadingDialog: false,
      isLoadingContents: true,
      isContentsNotFound: false,
      isSideNavExpand: false,

      showEditMenu: null,
      selectMenuId: null,
      userThumbList: [
        '',
        '',
        '',
      ],
      menuItems: [],
      userItems: Array.from({length: 30}, (_, i) => {
        return {
          thumbnailUrl: sampleThumbnailUrls[Math.floor(Math.random() * sampleThumbnailUrls.length)],
          name: sampleNames[Math.floor(Math.random() * sampleNames.length)],
          isOnline: [true, false][Math.floor(Math.random() * 2)],
        };
      }),

      // edit board lines
      boardHeaderTitle: '',
    };
  },
  created() {
    Promise.all([
      this.$loadScript('/js/editorjs.js'),
      this.$loadScript('/js/editorjs-embed.js'),
      this.$loadScript('/js/editorjs-header.js'),
      this.$loadScript('/js/editorjs-inline-image.js'),
    ]).then(() => {
      this.initData();
    });
  },
  methods: {
    async initData() {
      const res = await BoardService.getBoardMenus();
      this.menuItems = res ?? [];

      this.isLoading = false;

      setTimeout(() => {
        const selectMenuId = parseInt(this.$route.query.menuId ?? null);
        this.selectMenuId = isNaN(selectMenuId) ? null : selectMenuId;
        this.isLoadingContents = false;
      }, 500);
    },
    async addMenu(upperMenuId, title) {
      this.isLoadingDialog = true;

      const res = await BoardService.createMenu(upperMenuId, title);
      console.log('addMenu res =>');
      console.log(res);
      this.menuItems = res ?? [];

      this.isLoadingDialog = false;
    },
    async deleteMenu(menuId) {
      this.isLoadingDialog = true;

      const res = await BoardService.deleteMenu(menuId);
      console.log('deleteMenu res =>');
      console.log(res);
      this.menuItems = res ?? [];

      if (menuId === this.selectMenuId) {
        this.selectMenuId = null;
      }

      this.isLoadingDialog = false;

      this.onClickMenu(null);
    },
    onClickMenu(menuId) {
      const query = Object.assign({}, this.$route.query);
      if (menuId === null) {
        delete query.menuId;
      } else {
        query.menuId = menuId;
      }
      this.$router.push({ name: this.$route.name, query: query });
    },
    onChangeBoardHeaderTitle(menuId, value) {
      this.boardHeaderTitleSaveToServer(this, menuId, value);
    },
    boardHeaderTitleSaveToServer: debounce(async (appContext, menuId, title) => {
      const res = await BoardService.editMenu(menuId, title);
      console.log('boardHeaderTitleSaveToServer res =>');
      console.log(res);
      appContext.menuItems = res ?? [];
    }, 200),
  },
  computed: {
    sideNavMenuItems() {
      const resultList = [];

      for (const menuItem of this.menuItems) {
        resultList.push({
          depth: 0,
          id: menuItem.id,
          title: menuItem.title,
        });

        const d1Items = menuItem.sub_items ?? [];
        for (const d1Item of d1Items) {
          resultList.push({
            depth: 1,
            id: d1Item.id,
            title: d1Item.title,
          });

          const d2Items = d1Item.sub_items ?? [];
          for (const d2Item of d2Items) {
            resultList.push({
              depth: 2,
              id: d2Item.id,
              title: d2Item.title,
            });
          }
        }
      }

      return resultList;
    },
  },
}
</script>
