<template>
  <div class="v2-wrapper">
    <div class="v2w-header-line"></div>
    <div class="v2w-header-sub-line"></div>
    <div class="v2w-body-wrap">
      <slot/>
    </div>
    <div :class="['v2w-header-container-wrap', {'active': this.isOpenSideNav}]">
      <div class="v2-base-container">
        <WhLogoLink class="wh-logo-link"/>
        <div class="wh-hamburger-btn"
             @click="() => this.isOpenSideNav = true"
        >
          <img src="@/assets/images/hamburger-menu-btn.png" alt="">
        </div>
        <div class="wh-title-wrap">
          <p>{{ this.headerTitle }}</p>
        </div>
        <div :class="['wh-list-wrap', {'active': this.isOpenSideNav}]">
          <div class="whl-header-wrap">
            <WhLogoLink class="lh-logo-link"/>
          </div>
          <div class="whl-body-wrap">
            <template v-for="(routerItem, index) in this.routerItems" :key="index">
              <div class="whl-item">
                <router-link
                  :class="['li-link', {'active': routerItem.isActive}]"
                  :to="{ name: routerItem.routerName }"
                  @click="() => this.isOpenSideNav = false"
                >
                  <img :src="routerItem.iconUrl" alt="">
                  <p>{{ routerItem.title }}</p>
                </router-link>
                <template v-if="routerItem.isActive">
                  <div class="v2w-sub-header-container-wrap">
                    <div class="v2-base-container">
                      <template v-for="(subItem, subIndex) in routerItem.subItems" :key="subIndex">
                        <router-link
                          :class="['li-sub-link', {'active': subItem.isActive}]"
                          :to="{ name: subItem.routerName }"
                          @click="() => this.isOpenSideNav = false"
                        >
                          {{ subItem.title }}
                        </router-link>
                      </template>
                    </div>
                  </div>
                </template>
              </div>
            </template>
          </div>
        </div>
        <div class="wh-right-links">
          <button class="whr-btn" @click="() => this.signOutEvent()">로그아웃</button>
        </div>
      </div>
    </div>
    <div :class="['v2w-mobile-side-shadow', {'active': this.isOpenSideNav}]"
         @click="() => this.isOpenSideNav = false"
    ></div>
  </div>
  <slot name="popups"/>
</template>

<script>
import {mapActions} from "vuex";
import utils from "@/utils";
import Image from "@/components/Image.vue";
import WhLogoLink from "@/components/WhLogoLink.vue";

export default {
  name: 'V2Wrapper',
  components: {WhLogoLink, Image},
  props: {},
  data() {
    return {
      isOpenSideNav: false,
      utils,
    };
  },
  methods: {
    signOutEvent() {
      this.signout();
      this.$router.push({ name: 'login' });
    },
    ...mapActions('auth', ['signout']),
  },
  computed: {
    routerItems() {
      const curRouterName = this.$route.name;
      const curRoutePath = this.$route.path;

      const curRouterItems = [utils.v2TeacherRouterItems, utils.v2StudentRouterItems].find(routerItems => {
        return routerItems.findIndex(routerItem => {
          return routerItem.subItems.findIndex(subItem => {
            return subItem.routerName == curRouterName || curRoutePath.includes(subItem.path);
          }) >= 0;
        }) >= 0;
      });
      if (!curRouterItems) return [];

      return curRouterItems.map(item => {
        let isActive = false;

        item.routerName = item.subItems[0].routerName;

        item.subItems = item.subItems.map(subItem => {
          subItem.isActive = subItem.routerName == curRouterName || curRoutePath.includes(subItem.path);
          if (subItem.isActive) {
            isActive = true;
          }

          return subItem;
        });

        item.isActive = isActive;

        return item;
      });
    },
    headerTitle() {
      const item = this.routerItems.find(e => e.isActive);
      return item?.title ?? '';
    },
  },
}
</script>
