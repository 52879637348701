<template>
  <div class="self-learning-mode-wrap">
    <V2Wrapper>
      <div class="slmw-inner">
        <div class="v2-base-container">
          <div class="sec-left-wrap">
            <div class="sec-tab-wrap">
              <p>범위 선택</p>
              <div class="st-divider"></div>
              <div class="select-wrap">
                <select>
                  <option selected>중학교</option>
                </select>
                <img src="@/assets/images/arrow-right-7.png" alt="">
                <select>
                  <option selected>2-1</option>
                </select>
              </div>
            </div>
            <div class="sec-list-wrap">
              <V2TreeList
                :nodes="this.treeNodes"
              />
            </div>
          </div>
          <div class="sec-right-wrap">
            <div class="sec-tab-list">
              <template v-for="(tabName, index) in this.tabs" :key="index">
                <div :class="['tl-item', {'active': index === this.selectTabIndex}]"
                     @click="() => this.selectTabIndex = index"
                >
                  <p>{{ tabName }}</p>
                  <div></div>
                </div>
              </template>
            </div>
            <div class="sec-body-wrap">
              <template v-for="(unitItem, midIndex) in this.settingItems" :key="midIndex">
                <div class="sb-item">
                  <h5>{{ unitItem.title }}</h5>
                  <div class="sbi-list-wrap">
                    <template v-for="(item, subIndex) in unitItem.items" :key="subIndex">
                      <button :class="['il-item', {'active': item.isSelect}]"
                              @click="() => this.onChangeSettingItem(midIndex, subIndex, !item.isSelect)"
                      >
                        <span>{{ item.name }}</span>
                      </button>
                    </template>
                  </div>
                </div>
              </template>
            </div>
            <div class="sec-button-wrap">
              <button>
                <span>작성 완료</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </V2Wrapper>
  </div>
</template>

<script>
import V2Wrapper from "@/components/V2Wrapper.vue";
import V2TreeList from "@/components/V2TreeList.vue";
import {mapActions, mapGetters} from "vuex";

export default {
  name: 'TeacherSelfLearningMode',
  components: {V2TreeList, V2Wrapper},
  data() {
    return {
      tabs: [
        '진단평가',
        '단원평가',
      ],
      selectTabIndex: 0,
      treeNodes: {
        "1": {
          "text": "1. 화학 반응의 규칙과 에너지 변화",
          "depth": 1,
          "obj": {
            "id": 523,
            "school_type": "중학교",
            "school_year": "3",
            "term": "1",
            "problem_subject": "과학",
            "name": "1. 화학 반응의 규칙과 에너지 변화",
            "workbook_number": null,
            "update_dt": "2023-05-31T09:08:59.675744",
            "concept_vid_url": null,
            "upper_pattern": null,
            "workbook": 7,
            "mock": null
          },
          "children": [
            "5",
            "6",
            "7"
          ],
          "id": "1",
          "parent": null,
          "state": {
            "checked": false,
          }
        },
        "2": {
          "text": "2. 기권과 날씨",
          "depth": 1,
          "obj": {
            "id": 524,
            "school_type": "중학교",
            "school_year": "3",
            "term": "1",
            "problem_subject": "과학",
            "name": "2. 기권과 날씨",
            "workbook_number": null,
            "update_dt": "2023-05-31T09:09:40.057225",
            "concept_vid_url": null,
            "upper_pattern": null,
            "workbook": 7,
            "mock": null
          },
          "children": [
            "8",
            "9",
            "10",
            "11"
          ],
          "id": "2",
          "parent": null,
          "state": {
            "checked": false
          }
        },
        "3": {
          "text": "3. 운동과 에너지",
          "depth": 1,
          "obj": {
            "id": 525,
            "school_type": "중학교",
            "school_year": "3",
            "term": "1",
            "problem_subject": "과학",
            "name": "3. 운동과 에너지",
            "workbook_number": null,
            "update_dt": "2023-05-31T09:09:59.144090",
            "concept_vid_url": null,
            "upper_pattern": null,
            "workbook": 7,
            "mock": null
          },
          "children": [
            "12",
            "13"
          ],
          "id": "3",
          "parent": null,
          "state": {
            "checked": false,
          },
        },
        "4": {
          "text": "4. 자극과 반응",
          "depth": 1,
          "obj": {
            "id": 526,
            "school_type": "중학교",
            "school_year": "3",
            "term": "1",
            "problem_subject": "과학",
            "name": "4. 자극과 반응",
            "workbook_number": null,
            "update_dt": "2023-05-31T09:10:12.739364",
            "concept_vid_url": null,
            "upper_pattern": null,
            "workbook": 7,
            "mock": null
          },
          "children": [
            "14",
            "15"
          ],
          "id": "4",
          "parent": null,
          "state": {
            "checked": false
          }
        },
        "5": {
          "text": "1) 물질 변화와 화학 반응식",
          "depth": 2,
          "obj": {
            "id": 531,
            "school_type": null,
            "school_year": null,
            "term": null,
            "problem_subject": null,
            "name": "1) 물질 변화와 화학 반응식",
            "workbook_number": "1) 물질 변화와 화학 반응식 10~19, 41~44p",
            "update_dt": "2023-05-31T09:25:23.000499",
            "concept_vid_url": null,
            "upper_pattern": 523,
            "workbook": null,
            "mock": null
          },
          "children": [
            "16",
            "17"
          ],
          "state": {
            "checked": false,
          }
        },
        "6": {
          "text": "2) 화학 반응의 규칙",
          "depth": 2,
          "obj": {
            "id": 532,
            "school_type": null,
            "school_year": null,
            "term": null,
            "problem_subject": null,
            "name": "2) 화학 반응의 규칙",
            "workbook_number": "2) 화학 반응의 규칙 20~33, 41~44p",
            "update_dt": "2023-05-31T09:25:36.322811",
            "concept_vid_url": null,
            "upper_pattern": 523,
            "workbook": null,
            "mock": null
          },
          "children": [
            "18",
            "19",
            "20"
          ],
          "state": {
            "checked": false,
          }
        },
        "7": {
          "text": "3) 화학 반응에서의 에너지 출입",
          "depth": 2,
          "obj": {
            "id": 533,
            "school_type": null,
            "school_year": null,
            "term": null,
            "problem_subject": null,
            "name": "3) 화학 반응에서의 에너지 출입",
            "workbook_number": "3) 화학 반응에서의 에너지 출입 34~44p",
            "update_dt": "2023-05-31T09:22:05.874628",
            "concept_vid_url": null,
            "upper_pattern": 523,
            "workbook": null,
            "mock": null
          },
          "children": [
            "21"
          ],
          "state": {
            "checked": false,
          }
        },
        "8": {
          "text": "1) 기권과 지구 기온",
          "depth": 2,
          "obj": {
            "id": 534,
            "school_type": null,
            "school_year": null,
            "term": null,
            "problem_subject": null,
            "name": "1) 기권과 지구 기온",
            "workbook_number": "1) 기권과 지구 기온 48~57, 92~96p",
            "update_dt": "2023-05-31T09:30:28.245907",
            "concept_vid_url": null,
            "upper_pattern": 524,
            "workbook": null,
            "mock": null
          },
          "children": [
            "22",
            "23"
          ],
          "state": {
            "checked": false
          }
        },
        "9": {
          "text": "2) 구름과 강수",
          "depth": 2,
          "obj": {
            "id": 535,
            "school_type": null,
            "school_year": null,
            "term": null,
            "problem_subject": null,
            "name": "2) 구름과 강수",
            "workbook_number": "2) 구름과 강수 58~71, 92~96p",
            "update_dt": "2023-05-31T09:31:01.850114",
            "concept_vid_url": null,
            "upper_pattern": 524,
            "workbook": null,
            "mock": null
          },
          "children": [
            "24",
            "25",
            "26",
            "27"
          ],
          "state": {
            "checked": false
          }
        },
        "10": {
          "text": "3) 기압과 바람",
          "depth": 2,
          "obj": {
            "id": 536,
            "school_type": null,
            "school_year": null,
            "term": null,
            "problem_subject": null,
            "name": "3) 기압과 바람",
            "workbook_number": "3) 기압과 바람 72~81, 92~96p",
            "update_dt": "2023-05-31T09:31:47.368113",
            "concept_vid_url": null,
            "upper_pattern": 524,
            "workbook": null,
            "mock": null
          },
          "children": [
            "28",
            "29"
          ],
          "state": {
            "checked": false
          }
        },
        "11": {
          "text": "4) 날씨의 변화",
          "depth": 2,
          "obj": {
            "id": 537,
            "school_type": null,
            "school_year": null,
            "term": null,
            "problem_subject": null,
            "name": "4) 날씨의 변화",
            "workbook_number": "4) 날씨의 변화 82~96p",
            "update_dt": "2023-05-31T09:32:17.112818",
            "concept_vid_url": null,
            "upper_pattern": 524,
            "workbook": null,
            "mock": null
          },
          "children": [
            "30",
            "31",
            "32",
            "33"
          ],
          "state": {
            "checked": false
          }
        },
        "12": {
          "text": "1) 운동",
          "depth": 2,
          "obj": {
            "id": 538,
            "school_type": null,
            "school_year": null,
            "term": null,
            "problem_subject": null,
            "name": "1) 운동",
            "workbook_number": "1) 운동 100~111, 122~126p",
            "update_dt": "2023-05-31T09:33:04.758074",
            "concept_vid_url": null,
            "upper_pattern": 525,
            "workbook": null,
            "mock": null
          },
          "children": [
            "34",
            "35",
            "36"
          ],
          "state": {
            "checked": false,
          }
        },
        "13": {
          "text": "2) 일과 에너지",
          "depth": 2,
          "obj": {
            "id": 539,
            "school_type": null,
            "school_year": null,
            "term": null,
            "problem_subject": null,
            "name": "2) 일과 에너지",
            "workbook_number": "2) 일과 에너지 112~126p",
            "update_dt": "2023-05-31T09:33:28.413864",
            "concept_vid_url": null,
            "upper_pattern": 525,
            "workbook": null,
            "mock": null
          },
          "children": [
            "37",
            "38",
            "39"
          ],
          "state": {
            "checked": false,
          }
        },
        "14": {
          "text": "1) 감각 기관",
          "depth": 2,
          "obj": {
            "id": 540,
            "school_type": null,
            "school_year": null,
            "term": null,
            "problem_subject": null,
            "name": "1) 감각 기관",
            "workbook_number": "1) 감각 기관 130~139, 153~157p",
            "update_dt": "2023-05-31T09:34:04.864024",
            "concept_vid_url": null,
            "upper_pattern": 526,
            "workbook": null,
            "mock": null
          },
          "children": [
            "40",
            "41",
            "42",
            "43"
          ],
          "state": {
            "checked": false
          }
        },
        "15": {
          "text": "2) 신경계와 호르몬",
          "depth": 2,
          "obj": {
            "id": 541,
            "school_type": null,
            "school_year": null,
            "term": null,
            "problem_subject": null,
            "name": "2) 신경계와 호르몬",
            "workbook_number": "2) 신경계와 호르몬 140~157p",
            "update_dt": "2023-05-31T09:34:42.184598",
            "concept_vid_url": null,
            "upper_pattern": 526,
            "workbook": null,
            "mock": null
          },
          "children": [
            "44",
            "45",
            "46",
            "47"
          ],
          "state": {
            "checked": false
          }
        },
        "16": {
          "text": "(1) 물질 변화",
          "depth": 3,
          "obj": {
            "id": 552,
            "school_type": null,
            "school_year": null,
            "term": null,
            "problem_subject": null,
            "name": "(1) 물질 변화",
            "workbook_number": null,
            "update_dt": "2024-05-31T13:56:42.432850",
            "concept_vid_url": "https://youtu.be/ZFKAbeNNzKA?si=GLqeS8kn-bkD5la2",
            "upper_pattern": 531,
            "workbook": null,
            "mock": null
          },
          "children": [
            "48",
            "49"
          ],
          "state": {
            "checked": false,
          }
        },
        "17": {
          "text": "(2) 화학 반응과 화학 반응식",
          "depth": 3,
          "obj": {
            "id": 553,
            "school_type": null,
            "school_year": null,
            "term": null,
            "problem_subject": null,
            "name": "(2) 화학 반응과 화학 반응식",
            "workbook_number": null,
            "update_dt": "2024-05-31T13:56:59.786887",
            "concept_vid_url": "https://youtu.be/ZFKAbeNNzKA?si=bo_S2EHvQg0VtRml&t=310",
            "upper_pattern": 531,
            "workbook": null,
            "mock": null
          },
          "children": [
            "50",
            "51"
          ],
          "state": {
            "checked": false,
          }
        },
        "18": {
          "text": "(1) 질량 보존 법칙",
          "depth": 3,
          "obj": {
            "id": 554,
            "school_type": null,
            "school_year": null,
            "term": null,
            "problem_subject": null,
            "name": "(1) 질량 보존 법칙",
            "workbook_number": null,
            "update_dt": "2024-05-31T13:57:16.064964",
            "concept_vid_url": "https://youtu.be/5rZnJ3hPD5c?si=iOTrdEBt5YyAsc6x",
            "upper_pattern": 532,
            "workbook": null,
            "mock": null
          },
          "children": [
            "52",
            "53",
            "54",
            "55"
          ],
          "state": {
            "checked": false,
          }
        },
        "19": {
          "text": "(2) 일정 성분비 법칙",
          "depth": 3,
          "obj": {
            "id": 555,
            "school_type": null,
            "school_year": null,
            "term": null,
            "problem_subject": null,
            "name": "(2) 일정 성분비 법칙",
            "workbook_number": null,
            "update_dt": "2024-05-31T13:57:35.447965",
            "concept_vid_url": "https://youtu.be/5rZnJ3hPD5c?si=iOTrdEBt5YyAsc6x",
            "upper_pattern": 532,
            "workbook": null,
            "mock": null
          },
          "children": [
            "56",
            "57",
            "58",
            "59"
          ],
          "state": {
            "checked": false,
          }
        },
        "20": {
          "text": "(3) 기체 반응 법칙",
          "depth": 3,
          "obj": {
            "id": 556,
            "school_type": null,
            "school_year": null,
            "term": null,
            "problem_subject": null,
            "name": "(3) 기체 반응 법칙",
            "workbook_number": null,
            "update_dt": "2024-05-31T14:00:03.179382",
            "concept_vid_url": "https://youtu.be/5rZnJ3hPD5c?si=_qzzL_pRYRGgtdq-&t=205",
            "upper_pattern": 532,
            "workbook": null,
            "mock": null
          },
          "children": [
            "60",
            "61"
          ],
          "state": {
            "checked": false,
          }
        },
        "21": {
          "text": "(1) 화학 반응에서의 에너지 출입의 특징",
          "depth": 3,
          "obj": {
            "id": 557,
            "school_type": null,
            "school_year": null,
            "term": null,
            "problem_subject": null,
            "name": "(1) 화학 반응에서의 에너지 출입의 특징",
            "workbook_number": null,
            "update_dt": "2024-05-31T14:00:30.120223",
            "concept_vid_url": "https://youtu.be/uluzybSDyzU?si=TXvEgQ3KHM7pZtBh",
            "upper_pattern": 533,
            "workbook": null,
            "mock": null
          },
          "children": [
            "62"
          ],
          "state": {
            "checked": false,
          }
        },
        "22": {
          "text": "(1) 기권의 층상 구조",
          "depth": 3,
          "obj": {
            "id": 558,
            "school_type": null,
            "school_year": null,
            "term": null,
            "problem_subject": null,
            "name": "(1) 기권의 층상 구조",
            "workbook_number": null,
            "update_dt": "2024-05-31T14:00:47.147490",
            "concept_vid_url": "https://youtu.be/BgDXvQRVyXI?si=zFIaPTrl_rLs-Yly",
            "upper_pattern": 534,
            "workbook": null,
            "mock": null
          },
          "children": [
            "63",
            "64"
          ]
        },
        "23": {
          "text": "(2) 지구의 복사 평형과 지구 온난화",
          "depth": 3,
          "obj": {
            "id": 559,
            "school_type": null,
            "school_year": null,
            "term": null,
            "problem_subject": null,
            "name": "(2) 지구의 복사 평형과 지구 온난화",
            "workbook_number": null,
            "update_dt": "2024-05-31T14:01:04.310952",
            "concept_vid_url": "https://youtu.be/BgDXvQRVyXI?si=6_xVCbftQZz3-_xr&t=390",
            "upper_pattern": 534,
            "workbook": null,
            "mock": null
          },
          "children": [
            "65",
            "66",
            "67"
          ]
        },
        "24": {
          "text": "(1) 대기 중의 수증기",
          "depth": 3,
          "obj": {
            "id": 560,
            "school_type": null,
            "school_year": null,
            "term": null,
            "problem_subject": null,
            "name": "(1) 대기 중의 수증기",
            "workbook_number": null,
            "update_dt": "2024-06-17T10:25:28.959022",
            "concept_vid_url": "https://youtu.be/oKuKpamKfl4?si=ao6dYQsDlqOHKjSj",
            "upper_pattern": 535,
            "workbook": null,
            "mock": null
          },
          "children": [
            "68",
            "69",
            "70"
          ]
        },
        "25": {
          "text": "(2) 상대 습도",
          "depth": 3,
          "obj": {
            "id": 561,
            "school_type": null,
            "school_year": null,
            "term": null,
            "problem_subject": null,
            "name": "(2) 상대 습도",
            "workbook_number": null,
            "update_dt": "2024-06-17T10:25:51.056469",
            "concept_vid_url": "https://youtu.be/oKuKpamKfl4?si=OqCsLeGf_b8N4IpR&t=525",
            "upper_pattern": 535,
            "workbook": null,
            "mock": null
          },
          "children": [
            "71",
            "72",
            "73"
          ]
        },
        "26": {
          "text": "(3) 구름",
          "depth": 3,
          "obj": {
            "id": 562,
            "school_type": null,
            "school_year": null,
            "term": null,
            "problem_subject": null,
            "name": "(3) 구름",
            "workbook_number": null,
            "update_dt": "2024-06-17T10:26:13.945196",
            "concept_vid_url": "https://youtu.be/s4I3oCdzg5k?si=4auyZGmsvTMA3a7x",
            "upper_pattern": 535,
            "workbook": null,
            "mock": null
          },
          "children": [
            "74",
            "75",
            "76"
          ]
        },
        "27": {
          "text": "(4) 강수",
          "depth": 3,
          "obj": {
            "id": 563,
            "school_type": null,
            "school_year": null,
            "term": null,
            "problem_subject": null,
            "name": "(4) 강수",
            "workbook_number": null,
            "update_dt": "2024-06-17T10:26:29.951914",
            "concept_vid_url": "https://youtu.be/s4I3oCdzg5k?si=BKSHVdrOtxSujip9&t=264",
            "upper_pattern": 535,
            "workbook": null,
            "mock": null
          },
          "children": [
            "77"
          ]
        },
        "28": {
          "text": "(1) 기압",
          "depth": 3,
          "obj": {
            "id": 564,
            "school_type": null,
            "school_year": null,
            "term": null,
            "problem_subject": null,
            "name": "(1) 기압",
            "workbook_number": null,
            "update_dt": "2024-06-17T10:26:53.890518",
            "concept_vid_url": "https://youtu.be/gmhhtq_sSeE?si=6dI1FJHy1KR9-FzG",
            "upper_pattern": 536,
            "workbook": null,
            "mock": null
          },
          "children": [
            "78",
            "79",
            "80"
          ]
        },
        "29": {
          "text": "(2) 바람",
          "depth": 3,
          "obj": {
            "id": 565,
            "school_type": null,
            "school_year": null,
            "term": null,
            "problem_subject": null,
            "name": "(2) 바람",
            "workbook_number": null,
            "update_dt": "2024-06-17T10:27:09.376538",
            "concept_vid_url": "https://youtu.be/gmhhtq_sSeE?si=aQrLsyCUk5cJD-_G&t=358",
            "upper_pattern": 536,
            "workbook": null,
            "mock": null
          },
          "children": [
            "81",
            "82"
          ]
        },
        "30": {
          "text": "(1) 기단",
          "depth": 3,
          "obj": {
            "id": 566,
            "school_type": null,
            "school_year": null,
            "term": null,
            "problem_subject": null,
            "name": "(1) 기단",
            "workbook_number": null,
            "update_dt": "2024-06-19T09:38:53.220525",
            "concept_vid_url": "https://youtu.be/gjkvwMulDrA?si=vZ5O2G0XBIlYljh6",
            "upper_pattern": 537,
            "workbook": null,
            "mock": null
          },
          "children": [
            "83",
            "84"
          ]
        },
        "31": {
          "text": "(2) 전선",
          "depth": 3,
          "obj": {
            "id": 567,
            "school_type": null,
            "school_year": null,
            "term": null,
            "problem_subject": null,
            "name": "(2) 전선",
            "workbook_number": null,
            "update_dt": "2024-06-19T09:39:14.950701",
            "concept_vid_url": "https://youtu.be/gjkvwMulDrA?si=b44FoEwkCPp7yiOi&t=168",
            "upper_pattern": 537,
            "workbook": null,
            "mock": null
          },
          "children": [
            "85",
            "86"
          ]
        },
        "32": {
          "text": "(3) 기압과 날씨",
          "depth": 3,
          "obj": {
            "id": 568,
            "school_type": null,
            "school_year": null,
            "term": null,
            "problem_subject": null,
            "name": "(3) 기압과 날씨",
            "workbook_number": null,
            "update_dt": "2024-06-19T09:39:37.360511",
            "concept_vid_url": "https://youtu.be/gjkvwMulDrA?si=hIm6IHSCpgM9ypXI&t=436",
            "upper_pattern": 537,
            "workbook": null,
            "mock": null
          },
          "children": [
            "87",
            "88"
          ]
        },
        "33": {
          "text": "(4) 우리나라 계절별 날씨",
          "depth": 3,
          "obj": {
            "id": 569,
            "school_type": null,
            "school_year": null,
            "term": null,
            "problem_subject": null,
            "name": "(4) 우리나라 계절별 날씨",
            "workbook_number": null,
            "update_dt": "2024-06-19T09:39:54.104655",
            "concept_vid_url": "https://youtu.be/gjkvwMulDrA?si=ewfkw_Xj0SXFSeKk&t=600",
            "upper_pattern": 537,
            "workbook": null,
            "mock": null
          },
          "children": [
            "89"
          ]
        },
        "34": {
          "text": "(1) 운동의 기록",
          "depth": 3,
          "obj": {
            "id": 570,
            "school_type": null,
            "school_year": null,
            "term": null,
            "problem_subject": null,
            "name": "(1) 운동의 기록",
            "workbook_number": null,
            "update_dt": "2024-06-25T15:47:16.176164",
            "concept_vid_url": "https://youtu.be/gFarp--68yE?si=BmOOkf7HGwBXxr4g",
            "upper_pattern": 538,
            "workbook": null,
            "mock": null
          },
          "children": [
            "90"
          ],
          "state": {
            "checked": false,
          }
        },
        "35": {
          "text": "(2) 등속 운동",
          "depth": 3,
          "obj": {
            "id": 571,
            "school_type": null,
            "school_year": null,
            "term": null,
            "problem_subject": null,
            "name": "(2) 등속 운동",
            "workbook_number": null,
            "update_dt": "2024-06-25T15:47:37.763786",
            "concept_vid_url": "https://youtu.be/gFarp--68yE?si=o077knbBnyQediA6&t=210",
            "upper_pattern": 538,
            "workbook": null,
            "mock": null
          },
          "children": [
            "91",
            "92"
          ],
          "state": {
            "checked": false,
          }
        },
        "36": {
          "text": "(3) 자유 낙하 운동",
          "depth": 3,
          "obj": {
            "id": 572,
            "school_type": null,
            "school_year": null,
            "term": null,
            "problem_subject": null,
            "name": "(3) 자유 낙하 운동",
            "workbook_number": null,
            "update_dt": "2024-06-25T15:47:49.873530",
            "concept_vid_url": "https://youtu.be/gFarp--68yE?si=zfxFIsakIlDjM4jX&t=412",
            "upper_pattern": 538,
            "workbook": null,
            "mock": null
          },
          "children": [
            "93",
            "94",
            "95"
          ],
          "state": {
            "checked": false,
          }
        },
        "37": {
          "text": "(1) 일과 에너지의 특징",
          "depth": 3,
          "obj": {
            "id": 573,
            "school_type": null,
            "school_year": null,
            "term": null,
            "problem_subject": null,
            "name": "(1) 일과 에너지의 특징",
            "workbook_number": null,
            "update_dt": "2024-07-02T13:20:56.991243",
            "concept_vid_url": "https://youtu.be/7zdxXQmjnnQ?si=uCDwQZi-5XbRHH34",
            "upper_pattern": 539,
            "workbook": null,
            "mock": null
          },
          "children": [
            "96",
            "97",
            "98"
          ],
          "state": {
            "checked": false,
          }
        },
        "38": {
          "text": "(2) 중력에 의한 위치 에너지",
          "depth": 3,
          "obj": {
            "id": 574,
            "school_type": null,
            "school_year": null,
            "term": null,
            "problem_subject": null,
            "name": "(2) 중력에 의한 위치 에너지",
            "workbook_number": null,
            "update_dt": "2024-07-02T13:21:16.387364",
            "concept_vid_url": "https://youtu.be/7zdxXQmjnnQ?si=SJbWy7R8bEDdtxO0&t=154",
            "upper_pattern": 539,
            "workbook": null,
            "mock": null
          },
          "children": [
            "99"
          ],
          "state": {
            "checked": false,
          }
        },
        "39": {
          "text": "(3) 중력이 한 일과 운동 에너지",
          "depth": 3,
          "obj": {
            "id": 575,
            "school_type": null,
            "school_year": null,
            "term": null,
            "problem_subject": null,
            "name": "(3) 중력이 한 일과 운동 에너지",
            "workbook_number": null,
            "update_dt": "2024-07-02T13:21:29.255491",
            "concept_vid_url": "https://youtu.be/7zdxXQmjnnQ?si=jial67eZpchi4sRL&t=408",
            "upper_pattern": 539,
            "workbook": null,
            "mock": null
          },
          "children": [
            "100",
            "101"
          ],
          "state": {
            "checked": false,
          }
        },
        "40": {
          "text": "(1) 시각",
          "depth": 3,
          "obj": {
            "id": 576,
            "school_type": null,
            "school_year": null,
            "term": null,
            "problem_subject": null,
            "name": "(1) 시각",
            "workbook_number": null,
            "update_dt": "2024-05-31T14:01:27.401273",
            "concept_vid_url": "https://youtu.be/orPuz8aB720?si=TM8qjPEkhBt1wtMK",
            "upper_pattern": 540,
            "workbook": null,
            "mock": null
          },
          "children": [
            "102",
            "103",
            "104"
          ]
        },
        "41": {
          "text": "(2) 피부 감각",
          "depth": 3,
          "obj": {
            "id": 577,
            "school_type": null,
            "school_year": null,
            "term": null,
            "problem_subject": null,
            "name": "(2) 피부 감각",
            "workbook_number": null,
            "update_dt": "2024-05-31T14:01:41.269891",
            "concept_vid_url": "https://youtu.be/sbbhOEi9BJQ?si=Q4wHgNLFGtY0S_ix&t=163",
            "upper_pattern": 540,
            "workbook": null,
            "mock": null
          },
          "children": [
            "105",
            "106"
          ]
        },
        "42": {
          "text": "(3) 청각 평형 감각",
          "depth": 3,
          "obj": {
            "id": 578,
            "school_type": null,
            "school_year": null,
            "term": null,
            "problem_subject": null,
            "name": "(3) 청각 평형 감각",
            "workbook_number": null,
            "update_dt": "2024-05-31T14:02:01.202656",
            "concept_vid_url": "https://youtu.be/orPuz8aB720?si=eJ_FPdaXlExgLy3X&t=323",
            "upper_pattern": 540,
            "workbook": null,
            "mock": null
          },
          "children": [
            "107",
            "108"
          ]
        },
        "43": {
          "text": "(4) 후각과 미각",
          "depth": 3,
          "obj": {
            "id": 579,
            "school_type": null,
            "school_year": null,
            "term": null,
            "problem_subject": null,
            "name": "(4) 후각과 미각",
            "workbook_number": null,
            "update_dt": "2024-05-31T14:02:22.018521",
            "concept_vid_url": "https://youtu.be/sbbhOEi9BJQ?si=W08075FheMsb_V2s",
            "upper_pattern": 540,
            "workbook": null,
            "mock": null
          },
          "children": [
            "109",
            "110"
          ]
        },
        "44": {
          "text": "(1) 신경계",
          "depth": 3,
          "obj": {
            "id": 581,
            "school_type": null,
            "school_year": null,
            "term": null,
            "problem_subject": null,
            "name": "(1) 신경계",
            "workbook_number": null,
            "update_dt": "2024-05-31T14:02:43.858591",
            "concept_vid_url": "https://youtu.be/xf69mVYcDQk?si=sAPxy8JwXaNfYxBg",
            "upper_pattern": 541,
            "workbook": null,
            "mock": null
          },
          "children": [
            "111",
            "112",
            "113"
          ]
        },
        "45": {
          "text": "(2) 자극에 따른 반응의 경로",
          "depth": 3,
          "obj": {
            "id": 582,
            "school_type": null,
            "school_year": null,
            "term": null,
            "problem_subject": null,
            "name": "(2) 자극에 따른 반응의 경로",
            "workbook_number": null,
            "update_dt": "2024-05-31T14:02:58.065373",
            "concept_vid_url": "https://youtu.be/xf69mVYcDQk?si=tvmtk-etAjK69Xb6&t=424",
            "upper_pattern": 541,
            "workbook": null,
            "mock": null
          },
          "children": [
            "114",
            "115"
          ]
        },
        "46": {
          "text": "(3) 호르몬",
          "depth": 3,
          "obj": {
            "id": 583,
            "school_type": null,
            "school_year": null,
            "term": null,
            "problem_subject": null,
            "name": "(3) 호르몬",
            "workbook_number": null,
            "update_dt": "2024-05-31T14:14:19.062618",
            "concept_vid_url": "https://youtu.be/6DwB5KIqVPk?si=X0kbzpo4bsVSmOy_",
            "upper_pattern": 541,
            "workbook": null,
            "mock": null
          },
          "children": [
            "116",
            "117",
            "118"
          ]
        },
        "47": {
          "text": "(4) 항상성",
          "depth": 3,
          "obj": {
            "id": 584,
            "school_type": null,
            "school_year": null,
            "term": null,
            "problem_subject": null,
            "name": "(4) 항상성",
            "workbook_number": null,
            "update_dt": "2024-05-31T14:14:38.114087",
            "concept_vid_url": "https://youtu.be/6DwB5KIqVPk?si=gXgZY15K1HLoM5Sg&t=280",
            "upper_pattern": 541,
            "workbook": null,
            "mock": null
          },
          "children": [
            "119",
            "120"
          ]
        },
        "48": {
          "text": "[1] 물리 변화와 화학 변화",
          "depth": 4,
          "obj": {
            "id": 609,
            "school_type": null,
            "school_year": null,
            "term": null,
            "problem_subject": null,
            "name": "[1] 물리 변화와 화학 변화",
            "workbook_number": null,
            "update_dt": "2023-05-31T10:17:45.084524",
            "concept_vid_url": null,
            "upper_pattern": 552,
            "workbook": null,
            "mock": null
          },
          "state": {
            "checked": false,
          }
        },
        "49": {
          "text": "[2] 입자 배열의 변화",
          "depth": 4,
          "obj": {
            "id": 610,
            "school_type": null,
            "school_year": null,
            "term": null,
            "problem_subject": null,
            "name": "[2] 입자 배열의 변화",
            "workbook_number": null,
            "update_dt": "2023-05-31T10:17:56.615728",
            "concept_vid_url": null,
            "upper_pattern": 552,
            "workbook": null,
            "mock": null
          },
          "state": {
            "checked": false,
          }
        },
        "50": {
          "text": "[1] 화학 반응식의 정의와 나타내는 방법",
          "depth": 4,
          "obj": {
            "id": 611,
            "school_type": null,
            "school_year": null,
            "term": null,
            "problem_subject": null,
            "name": "[1] 화학 반응식의 정의와 나타내는 방법",
            "workbook_number": null,
            "update_dt": "2023-05-31T10:18:21.931459",
            "concept_vid_url": null,
            "upper_pattern": 553,
            "workbook": null,
            "mock": null
          },
          "state": {
            "checked": false,
          }
        },
        "51": {
          "text": "[2] 화학 반응식으로 알 수 있는 것",
          "depth": 4,
          "obj": {
            "id": 612,
            "school_type": null,
            "school_year": null,
            "term": null,
            "problem_subject": null,
            "name": "[2] 화학 반응식으로 알 수 있는 것",
            "workbook_number": null,
            "update_dt": "2023-05-31T10:18:38.097200",
            "concept_vid_url": null,
            "upper_pattern": 553,
            "workbook": null,
            "mock": null
          },
          "state": {
            "checked": false,
          }
        },
        "52": {
          "text": "[1] 질량 보존 법칙이 성립하는 까닭",
          "depth": 4,
          "obj": {
            "id": 613,
            "school_type": null,
            "school_year": null,
            "term": null,
            "problem_subject": null,
            "name": "[1] 질량 보존 법칙이 성립하는 까닭",
            "workbook_number": null,
            "update_dt": "2023-05-31T10:19:00.242731",
            "concept_vid_url": null,
            "upper_pattern": 554,
            "workbook": null,
            "mock": null
          },
          "state": {
            "checked": false,
          }
        },
        "53": {
          "text": "[2] 앙금 생성 반응에서 질량 변화",
          "depth": 4,
          "obj": {
            "id": 614,
            "school_type": null,
            "school_year": null,
            "term": null,
            "problem_subject": null,
            "name": "[2] 앙금 생성 반응에서 질량 변화",
            "workbook_number": null,
            "update_dt": "2023-05-31T10:19:18.333264",
            "concept_vid_url": null,
            "upper_pattern": 554,
            "workbook": null,
            "mock": null
          },
          "state": {
            "checked": false,
          }
        },
        "54": {
          "text": "[3] 기체 발생 반응에서 질량 변화",
          "depth": 4,
          "obj": {
            "id": 615,
            "school_type": null,
            "school_year": null,
            "term": null,
            "problem_subject": null,
            "name": "[3] 기체 발생 반응에서 질량 변화",
            "workbook_number": null,
            "update_dt": "2023-05-31T10:19:34.735175",
            "concept_vid_url": null,
            "upper_pattern": 554,
            "workbook": null,
            "mock": null
          },
          "state": {
            "checked": false,
          }
        },
        "55": {
          "text": "[4] 연소 반응에서 질량 변화",
          "depth": 4,
          "obj": {
            "id": 616,
            "school_type": null,
            "school_year": null,
            "term": null,
            "problem_subject": null,
            "name": "[4] 연소 반응에서 질량 변화",
            "workbook_number": null,
            "update_dt": "2023-05-31T10:19:50.588818",
            "concept_vid_url": null,
            "upper_pattern": 554,
            "workbook": null,
            "mock": null
          },
          "state": {
            "checked": false,
          }
        },
        "56": {
          "text": "[1] 일정 성분비 법칙이 성립하는 까닭",
          "depth": 4,
          "obj": {
            "id": 617,
            "school_type": null,
            "school_year": null,
            "term": null,
            "problem_subject": null,
            "name": "[1] 일정 성분비 법칙이 성립하는 까닭",
            "workbook_number": null,
            "update_dt": "2023-05-31T10:20:16.929637",
            "concept_vid_url": null,
            "upper_pattern": 555,
            "workbook": null,
            "mock": null
          },
          "state": {
            "checked": false,
          }
        },
        "57": {
          "text": "[2] 화합물을 구성하는 성분 원소의 질량비 구하기",
          "depth": 4,
          "obj": {
            "id": 618,
            "school_type": null,
            "school_year": null,
            "term": null,
            "problem_subject": null,
            "name": "[2] 화합물을 구성하는 성분 원소의 질량비 구하기",
            "workbook_number": null,
            "update_dt": "2023-05-31T10:20:51.625115",
            "concept_vid_url": null,
            "upper_pattern": 555,
            "workbook": null,
            "mock": null
          },
          "state": {
            "checked": false,
          }
        },
        "58": {
          "text": "[3] 볼트와 너트를 이용해 화합물을 만들 때의 질량비",
          "depth": 4,
          "obj": {
            "id": 619,
            "school_type": null,
            "school_year": null,
            "term": null,
            "problem_subject": null,
            "name": "[3] 볼트와 너트를 이용해 화합물을 만들 때의 질량비",
            "workbook_number": null,
            "update_dt": "2023-05-31T10:21:57.279122",
            "concept_vid_url": null,
            "upper_pattern": 555,
            "workbook": null,
            "mock": null
          },
          "state": {
            "checked": false,
          }
        },
        "59": {
          "text": "[4] 산화 구리 생성 반응에서 질량비 구하기",
          "depth": 4,
          "obj": {
            "id": 620,
            "school_type": null,
            "school_year": null,
            "term": null,
            "problem_subject": null,
            "name": "[4] 산화 구리 생성 반응에서 질량비 구하기",
            "workbook_number": null,
            "update_dt": "2023-05-31T10:22:39.971881",
            "concept_vid_url": null,
            "upper_pattern": 555,
            "workbook": null,
            "mock": null
          },
          "state": {
            "checked": false,
          }
        },
        "60": {
          "text": "[1] 기체 반응 법칙의 특징",
          "depth": 4,
          "obj": {
            "id": 621,
            "school_type": null,
            "school_year": null,
            "term": null,
            "problem_subject": null,
            "name": "[1] 기체 반응 법칙의 특징",
            "workbook_number": null,
            "update_dt": "2023-05-31T10:23:47.683005",
            "concept_vid_url": null,
            "upper_pattern": 556,
            "workbook": null,
            "mock": null
          },
          "state": {
            "checked": false,
          }
        },
        "61": {
          "text": "[2] 기체의 부피비와 분자 수의 비",
          "depth": 4,
          "obj": {
            "id": 622,
            "school_type": null,
            "school_year": null,
            "term": null,
            "problem_subject": null,
            "name": "[2] 기체의 부피비와 분자 수의 비",
            "workbook_number": null,
            "update_dt": "2023-05-31T13:11:41.157471",
            "concept_vid_url": null,
            "upper_pattern": 556,
            "workbook": null,
            "mock": null
          },
          "state": {
            "checked": false,
          }
        },
        "62": {
          "text": "[1] 발열 반응과 흡열 반응",
          "depth": 4,
          "obj": {
            "id": 623,
            "school_type": null,
            "school_year": null,
            "term": null,
            "problem_subject": null,
            "name": "[1] 발열 반응과 흡열 반응",
            "workbook_number": null,
            "update_dt": "2023-06-08T16:44:50.528134",
            "concept_vid_url": null,
            "upper_pattern": 557,
            "workbook": null,
            "mock": null
          },
          "state": {
            "checked": false,
          }
        },
        "63": {
          "text": "[1] 기권의 정의와 구분",
          "depth": 4,
          "obj": {
            "id": 625,
            "school_type": null,
            "school_year": null,
            "term": null,
            "problem_subject": null,
            "name": "[1] 기권의 정의와 구분",
            "workbook_number": null,
            "update_dt": "2023-05-31T10:26:41.300784",
            "concept_vid_url": null,
            "upper_pattern": 558,
            "workbook": null,
            "mock": null
          }
        },
        "64": {
          "text": "[2] 기권의 높이에 따른 기온 분포",
          "depth": 4,
          "obj": {
            "id": 626,
            "school_type": null,
            "school_year": null,
            "term": null,
            "problem_subject": null,
            "name": "[2] 기권의 높이에 따른 기온 분포",
            "workbook_number": null,
            "update_dt": "2023-05-31T10:26:52.754092",
            "concept_vid_url": null,
            "upper_pattern": 558,
            "workbook": null,
            "mock": null
          }
        },
        "65": {
          "text": "[1] 지구의 복사 평형",
          "depth": 4,
          "obj": {
            "id": 627,
            "school_type": null,
            "school_year": null,
            "term": null,
            "problem_subject": null,
            "name": "[1] 지구의 복사 평형",
            "workbook_number": null,
            "update_dt": "2023-05-31T10:27:17.035134",
            "concept_vid_url": null,
            "upper_pattern": 559,
            "workbook": null,
            "mock": null
          }
        },
        "66": {
          "text": "[2] 온실 효과",
          "depth": 4,
          "obj": {
            "id": 628,
            "school_type": null,
            "school_year": null,
            "term": null,
            "problem_subject": null,
            "name": "[2] 온실 효과",
            "workbook_number": null,
            "update_dt": "2023-05-31T10:27:46.087503",
            "concept_vid_url": null,
            "upper_pattern": 559,
            "workbook": null,
            "mock": null
          }
        },
        "67": {
          "text": "[3] 지구 온난화",
          "depth": 4,
          "obj": {
            "id": 629,
            "school_type": null,
            "school_year": null,
            "term": null,
            "problem_subject": null,
            "name": "[3] 지구 온난화",
            "workbook_number": null,
            "update_dt": "2023-05-31T10:27:58.020969",
            "concept_vid_url": null,
            "upper_pattern": 559,
            "workbook": null,
            "mock": null
          }
        },
        "68": {
          "text": "[1] 포화상태와 불포화상태",
          "depth": 4,
          "obj": {
            "id": 630,
            "school_type": null,
            "school_year": null,
            "term": null,
            "problem_subject": null,
            "name": "[1] 포화상태와 불포화상태",
            "workbook_number": null,
            "update_dt": "2023-05-31T13:13:55.702320",
            "concept_vid_url": null,
            "upper_pattern": 560,
            "workbook": null,
            "mock": null
          }
        },
        "69": {
          "text": "[2] 포화 수증기량",
          "depth": 4,
          "obj": {
            "id": 631,
            "school_type": null,
            "school_year": null,
            "term": null,
            "problem_subject": null,
            "name": "[2] 포화 수증기량",
            "workbook_number": null,
            "update_dt": "2023-05-31T10:28:34.142819",
            "concept_vid_url": null,
            "upper_pattern": 560,
            "workbook": null,
            "mock": null
          }
        },
        "70": {
          "text": "[3] 이슬점",
          "depth": 4,
          "obj": {
            "id": 632,
            "school_type": null,
            "school_year": null,
            "term": null,
            "problem_subject": null,
            "name": "[3] 이슬점",
            "workbook_number": null,
            "update_dt": "2023-05-31T10:28:41.087146",
            "concept_vid_url": null,
            "upper_pattern": 560,
            "workbook": null,
            "mock": null
          }
        },
        "71": {
          "text": "[1] 상대 습도 계산",
          "depth": 4,
          "obj": {
            "id": 633,
            "school_type": null,
            "school_year": null,
            "term": null,
            "problem_subject": null,
            "name": "[1] 상대 습도 계산",
            "workbook_number": null,
            "update_dt": "2023-05-31T10:29:01.850085",
            "concept_vid_url": null,
            "upper_pattern": 561,
            "workbook": null,
            "mock": null
          }
        },
        "72": {
          "text": "[2] 상대 습도의 변화",
          "depth": 4,
          "obj": {
            "id": 634,
            "school_type": null,
            "school_year": null,
            "term": null,
            "problem_subject": null,
            "name": "[2] 상대 습도의 변화",
            "workbook_number": null,
            "update_dt": "2023-05-31T10:29:13.338201",
            "concept_vid_url": null,
            "upper_pattern": 561,
            "workbook": null,
            "mock": null
          }
        },
        "73": {
          "text": "[3] 맑은날 하루의 기온, 상대 습도, 이슬점 변화",
          "depth": 4,
          "obj": {
            "id": 635,
            "school_type": null,
            "school_year": null,
            "term": null,
            "problem_subject": null,
            "name": "[3] 맑은날 하루의 기온, 상대 습도, 이슬점 변화",
            "workbook_number": null,
            "update_dt": "2023-05-31T10:32:32.912139",
            "concept_vid_url": null,
            "upper_pattern": 561,
            "workbook": null,
            "mock": null
          }
        },
        "74": {
          "text": "[1] 구름의 생성 과정",
          "depth": 4,
          "obj": {
            "id": 636,
            "school_type": null,
            "school_year": null,
            "term": null,
            "problem_subject": null,
            "name": "[1] 구름의 생성 과정",
            "workbook_number": null,
            "update_dt": "2023-05-31T10:32:49.435211",
            "concept_vid_url": null,
            "upper_pattern": 562,
            "workbook": null,
            "mock": null
          }
        },
        "75": {
          "text": "[2] 공기가 상승하여 구름이 생성되는 경우",
          "depth": 4,
          "obj": {
            "id": 637,
            "school_type": null,
            "school_year": null,
            "term": null,
            "problem_subject": null,
            "name": "[2] 공기가 상승하여 구름이 생성되는 경우",
            "workbook_number": null,
            "update_dt": "2023-05-31T10:33:07.005966",
            "concept_vid_url": null,
            "upper_pattern": 562,
            "workbook": null,
            "mock": null
          }
        },
        "76": {
          "text": "[3] 구름의 모양",
          "depth": 4,
          "obj": {
            "id": 638,
            "school_type": null,
            "school_year": null,
            "term": null,
            "problem_subject": null,
            "name": "[3] 구름의 모양",
            "workbook_number": null,
            "update_dt": "2023-05-31T10:33:15.395575",
            "concept_vid_url": null,
            "upper_pattern": 562,
            "workbook": null,
            "mock": null
          }
        },
        "77": {
          "text": "[1] 강수 이론",
          "depth": 4,
          "obj": {
            "id": 639,
            "school_type": null,
            "school_year": null,
            "term": null,
            "problem_subject": null,
            "name": "[1] 강수 이론",
            "workbook_number": null,
            "update_dt": "2023-05-31T10:33:42.889837",
            "concept_vid_url": null,
            "upper_pattern": 563,
            "workbook": null,
            "mock": null
          }
        },
        "78": {
          "text": "[1] 기압의 정의",
          "depth": 4,
          "obj": {
            "id": 640,
            "school_type": null,
            "school_year": null,
            "term": null,
            "problem_subject": null,
            "name": "[1] 기압의 정의",
            "workbook_number": null,
            "update_dt": "2023-05-31T10:34:15.504605",
            "concept_vid_url": null,
            "upper_pattern": 564,
            "workbook": null,
            "mock": null
          }
        },
        "79": {
          "text": "[2] 기압의 측정",
          "depth": 4,
          "obj": {
            "id": 641,
            "school_type": null,
            "school_year": null,
            "term": null,
            "problem_subject": null,
            "name": "[2] 기압의 측정",
            "workbook_number": null,
            "update_dt": "2023-05-31T10:34:24.015721",
            "concept_vid_url": null,
            "upper_pattern": 564,
            "workbook": null,
            "mock": null
          }
        },
        "80": {
          "text": "[3] 기압의 변화",
          "depth": 4,
          "obj": {
            "id": 642,
            "school_type": null,
            "school_year": null,
            "term": null,
            "problem_subject": null,
            "name": "[3] 기압의 변화",
            "workbook_number": null,
            "update_dt": "2023-05-31T10:34:43.043675",
            "concept_vid_url": null,
            "upper_pattern": 564,
            "workbook": null,
            "mock": null
          }
        },
        "81": {
          "text": "[1] 바람이 부는 원리",
          "depth": 4,
          "obj": {
            "id": 643,
            "school_type": null,
            "school_year": null,
            "term": null,
            "problem_subject": null,
            "name": "[1] 바람이 부는 원리",
            "workbook_number": null,
            "update_dt": "2023-05-31T10:34:59.963180",
            "concept_vid_url": null,
            "upper_pattern": 565,
            "workbook": null,
            "mock": null
          }
        },
        "82": {
          "text": "[2] 해륙풍과 계절풍",
          "depth": 4,
          "obj": {
            "id": 644,
            "school_type": null,
            "school_year": null,
            "term": null,
            "problem_subject": null,
            "name": "[2] 해륙풍과 계절풍",
            "workbook_number": null,
            "update_dt": "2023-05-31T10:35:11.162608",
            "concept_vid_url": null,
            "upper_pattern": 565,
            "workbook": null,
            "mock": null
          }
        },
        "83": {
          "text": "[1] 기단의 발생지와 성질",
          "depth": 4,
          "obj": {
            "id": 645,
            "school_type": null,
            "school_year": null,
            "term": null,
            "problem_subject": null,
            "name": "[1] 기단의 발생지와 성질",
            "workbook_number": null,
            "update_dt": "2023-05-31T10:35:27.068325",
            "concept_vid_url": null,
            "upper_pattern": 566,
            "workbook": null,
            "mock": null
          }
        },
        "84": {
          "text": "[2] 우리나라에 영향을 주는 기단",
          "depth": 4,
          "obj": {
            "id": 646,
            "school_type": null,
            "school_year": null,
            "term": null,
            "problem_subject": null,
            "name": "[2] 우리나라에 영향을 주는 기단",
            "workbook_number": null,
            "update_dt": "2023-05-31T10:35:36.784609",
            "concept_vid_url": null,
            "upper_pattern": 566,
            "workbook": null,
            "mock": null
          }
        },
        "85": {
          "text": "[1] 전선의 종류와 특징",
          "depth": 4,
          "obj": {
            "id": 647,
            "school_type": null,
            "school_year": null,
            "term": null,
            "problem_subject": null,
            "name": "[1] 전선의 종류와 특징",
            "workbook_number": null,
            "update_dt": "2023-05-31T10:35:53.784768",
            "concept_vid_url": null,
            "upper_pattern": 567,
            "workbook": null,
            "mock": null
          }
        },
        "86": {
          "text": "[2] 폐색 전선과 정체 전선",
          "depth": 4,
          "obj": {
            "id": 648,
            "school_type": null,
            "school_year": null,
            "term": null,
            "problem_subject": null,
            "name": "[2] 폐색 전선과 정체 전선",
            "workbook_number": null,
            "update_dt": "2023-05-31T10:36:23.086845",
            "concept_vid_url": null,
            "upper_pattern": 567,
            "workbook": null,
            "mock": null
          }
        },
        "87": {
          "text": "[1] 고기압과 저기압",
          "depth": 4,
          "obj": {
            "id": 649,
            "school_type": null,
            "school_year": null,
            "term": null,
            "problem_subject": null,
            "name": "[1] 고기압과 저기압",
            "workbook_number": null,
            "update_dt": "2023-05-31T10:36:47.307039",
            "concept_vid_url": null,
            "upper_pattern": 568,
            "workbook": null,
            "mock": null
          }
        },
        "88": {
          "text": "[2] 온대 저기압의 이동과 날씨",
          "depth": 4,
          "obj": {
            "id": 650,
            "school_type": null,
            "school_year": null,
            "term": null,
            "problem_subject": null,
            "name": "[2] 온대 저기압의 이동과 날씨",
            "workbook_number": null,
            "update_dt": "2023-05-31T10:37:01.725540",
            "concept_vid_url": null,
            "upper_pattern": 568,
            "workbook": null,
            "mock": null
          }
        },
        "89": {
          "text": "[1] 우리나라의 계절별 일기도와 날씨",
          "depth": 4,
          "obj": {
            "id": 651,
            "school_type": null,
            "school_year": null,
            "term": null,
            "problem_subject": null,
            "name": "[1] 우리나라의 계절별 일기도와 날씨",
            "workbook_number": null,
            "update_dt": "2023-05-31T10:37:24.002723",
            "concept_vid_url": null,
            "upper_pattern": 569,
            "workbook": null,
            "mock": null
          }
        },
        "90": {
          "text": "[1] 운동과 속력",
          "depth": 4,
          "obj": {
            "id": 652,
            "school_type": null,
            "school_year": null,
            "term": null,
            "problem_subject": null,
            "name": "[1] 운동과 속력",
            "workbook_number": null,
            "update_dt": "2023-05-31T10:57:45.982040",
            "concept_vid_url": null,
            "upper_pattern": 570,
            "workbook": null,
            "mock": null
          },
          "state": {
            "checked": false,
          }
        },
        "91": {
          "text": "[1] 등속 운동의 정의",
          "depth": 4,
          "obj": {
            "id": 653,
            "school_type": null,
            "school_year": null,
            "term": null,
            "problem_subject": null,
            "name": "[1] 등속 운동의 정의",
            "workbook_number": null,
            "update_dt": "2023-05-31T10:58:03.972397",
            "concept_vid_url": null,
            "upper_pattern": 571,
            "workbook": null,
            "mock": null
          },
          "state": {
            "checked": false,
          }
        },
        "92": {
          "text": "[2] 등속 운동의 그래프",
          "depth": 4,
          "obj": {
            "id": 654,
            "school_type": null,
            "school_year": null,
            "term": null,
            "problem_subject": null,
            "name": "[2] 등속 운동의 그래프",
            "workbook_number": null,
            "update_dt": "2023-05-31T10:58:13.372029",
            "concept_vid_url": null,
            "upper_pattern": 571,
            "workbook": null,
            "mock": null
          },
          "state": {
            "checked": false,
          }
        },
        "93": {
          "text": "[1] 자유 낙하 운동의 정의",
          "depth": 4,
          "obj": {
            "id": 655,
            "school_type": null,
            "school_year": null,
            "term": null,
            "problem_subject": null,
            "name": "[1] 자유 낙하 운동의 정의",
            "workbook_number": null,
            "update_dt": "2023-05-31T10:58:34.208394",
            "concept_vid_url": null,
            "upper_pattern": 572,
            "workbook": null,
            "mock": null
          },
          "state": {
            "checked": false,
          }
        },
        "94": {
          "text": "[2] 질량이 다른 물체의 자유 낙하 운동",
          "depth": 4,
          "obj": {
            "id": 656,
            "school_type": null,
            "school_year": null,
            "term": null,
            "problem_subject": null,
            "name": "[2] 질량이 다른 물체의 자유 낙하 운동",
            "workbook_number": null,
            "update_dt": "2023-05-31T10:58:47.620545",
            "concept_vid_url": null,
            "upper_pattern": 572,
            "workbook": null,
            "mock": null
          },
          "state": {
            "checked": false,
          }
        },
        "95": {
          "text": "[3] 등속 운동과 자유 낙하 운동의 속력 그래프 비교",
          "depth": 4,
          "obj": {
            "id": 657,
            "school_type": null,
            "school_year": null,
            "term": null,
            "problem_subject": null,
            "name": "[3] 등속 운동과 자유 낙하 운동의 속력 그래프 비교",
            "workbook_number": null,
            "update_dt": "2023-05-31T10:59:06.535595",
            "concept_vid_url": null,
            "upper_pattern": 572,
            "workbook": null,
            "mock": null
          },
          "state": {
            "checked": false,
          }
        },
        "96": {
          "text": "[1] 일의 양",
          "depth": 4,
          "obj": {
            "id": 658,
            "school_type": null,
            "school_year": null,
            "term": null,
            "problem_subject": null,
            "name": "[1] 일의 양",
            "workbook_number": null,
            "update_dt": "2023-05-31T10:59:25.925571",
            "concept_vid_url": null,
            "upper_pattern": 573,
            "workbook": null,
            "mock": null
          },
          "state": {
            "checked": false,
          }
        },
        "97": {
          "text": "[2] 일의 양 계산하기",
          "depth": 4,
          "obj": {
            "id": 659,
            "school_type": null,
            "school_year": null,
            "term": null,
            "problem_subject": null,
            "name": "[2] 일의 양 계산하기",
            "workbook_number": null,
            "update_dt": "2023-05-31T10:59:36.716555",
            "concept_vid_url": null,
            "upper_pattern": 573,
            "workbook": null,
            "mock": null
          },
          "state": {
            "checked": false,
          }
        },
        "98": {
          "text": "[3] 일과 에너지의 정의",
          "depth": 4,
          "obj": {
            "id": 660,
            "school_type": null,
            "school_year": null,
            "term": null,
            "problem_subject": null,
            "name": "[3] 일과 에너지의 정의",
            "workbook_number": null,
            "update_dt": "2023-05-31T10:59:46.546869",
            "concept_vid_url": null,
            "upper_pattern": 573,
            "workbook": null,
            "mock": null
          },
          "state": {
            "checked": false,
          }
        },
        "99": {
          "text": "[1] 중력에 의한 위치 에너지의 크기",
          "depth": 4,
          "obj": {
            "id": 661,
            "school_type": null,
            "school_year": null,
            "term": null,
            "problem_subject": null,
            "name": "[1] 중력에 의한 위치 에너지의 크기",
            "workbook_number": null,
            "update_dt": "2023-05-31T11:00:06.710448",
            "concept_vid_url": null,
            "upper_pattern": 574,
            "workbook": null,
            "mock": null
          },
          "state": {
            "checked": false,
          }
        },
        "100": {
          "text": "[1] 운동 에너지",
          "depth": 4,
          "obj": {
            "id": 662,
            "school_type": null,
            "school_year": null,
            "term": null,
            "problem_subject": null,
            "name": "[1] 운동 에너지",
            "workbook_number": null,
            "update_dt": "2023-05-31T11:00:22.241430",
            "concept_vid_url": null,
            "upper_pattern": 575,
            "workbook": null,
            "mock": null
          },
          "state": {
            "checked": false,
          }
        },
        "101": {
          "text": "[2] 중력이 한 일과 운동 에너지",
          "depth": 4,
          "obj": {
            "id": 663,
            "school_type": null,
            "school_year": null,
            "term": null,
            "problem_subject": null,
            "name": "[2] 중력이 한 일과 운동 에너지",
            "workbook_number": null,
            "update_dt": "2023-05-31T11:00:32.650488",
            "concept_vid_url": null,
            "upper_pattern": 575,
            "workbook": null,
            "mock": null
          },
          "state": {
            "checked": false,
          }
        },
        "102": {
          "text": "[1] 물체를 보는 과정",
          "depth": 4,
          "obj": {
            "id": 664,
            "school_type": null,
            "school_year": null,
            "term": null,
            "problem_subject": null,
            "name": "[1] 물체를 보는 과정",
            "workbook_number": null,
            "update_dt": "2023-07-05T14:12:13.291735",
            "concept_vid_url": null,
            "upper_pattern": 576,
            "workbook": null,
            "mock": null
          }
        },
        "103": {
          "text": "[2] 눈의 구조와 기능",
          "depth": 4,
          "obj": {
            "id": 665,
            "school_type": null,
            "school_year": null,
            "term": null,
            "problem_subject": null,
            "name": "[2] 눈의 구조와 기능",
            "workbook_number": null,
            "update_dt": "2023-05-31T11:01:31.358425",
            "concept_vid_url": null,
            "upper_pattern": 576,
            "workbook": null,
            "mock": null
          }
        },
        "104": {
          "text": "[3] 눈의 조절 작용",
          "depth": 4,
          "obj": {
            "id": 666,
            "school_type": null,
            "school_year": null,
            "term": null,
            "problem_subject": null,
            "name": "[3] 눈의 조절 작용",
            "workbook_number": null,
            "update_dt": "2023-05-31T11:01:40.312351",
            "concept_vid_url": null,
            "upper_pattern": 576,
            "workbook": null,
            "mock": null
          }
        },
        "105": {
          "text": "[1] 피부의 온도 감각",
          "depth": 4,
          "obj": {
            "id": 667,
            "school_type": null,
            "school_year": null,
            "term": null,
            "problem_subject": null,
            "name": "[1] 피부의 온도 감각",
            "workbook_number": null,
            "update_dt": "2023-07-05T14:12:34.155310",
            "concept_vid_url": null,
            "upper_pattern": 577,
            "workbook": null,
            "mock": null
          }
        },
        "106": {
          "text": "[2] 감각점",
          "depth": 4,
          "obj": {
            "id": 668,
            "school_type": null,
            "school_year": null,
            "term": null,
            "problem_subject": null,
            "name": "[2] 감각점",
            "workbook_number": null,
            "update_dt": "2023-05-31T11:02:02.121324",
            "concept_vid_url": null,
            "upper_pattern": 577,
            "workbook": null,
            "mock": null
          }
        },
        "107": {
          "text": "[1] 귀의 구조와 기능",
          "depth": 4,
          "obj": {
            "id": 669,
            "school_type": null,
            "school_year": null,
            "term": null,
            "problem_subject": null,
            "name": "[1] 귀의 구조와 기능",
            "workbook_number": null,
            "update_dt": "2023-05-31T11:02:17.050023",
            "concept_vid_url": null,
            "upper_pattern": 578,
            "workbook": null,
            "mock": null
          }
        },
        "108": {
          "text": "[2] 평형 감각",
          "depth": 4,
          "obj": {
            "id": 670,
            "school_type": null,
            "school_year": null,
            "term": null,
            "problem_subject": null,
            "name": "[2] 평형 감각",
            "workbook_number": null,
            "update_dt": "2023-05-31T11:02:25.958925",
            "concept_vid_url": null,
            "upper_pattern": 578,
            "workbook": null,
            "mock": null
          }
        },
        "109": {
          "text": "[1] 후각 신경",
          "depth": 4,
          "obj": {
            "id": 671,
            "school_type": null,
            "school_year": null,
            "term": null,
            "problem_subject": null,
            "name": "[1] 후각 신경",
            "workbook_number": null,
            "update_dt": "2023-07-05T14:13:36.360666",
            "concept_vid_url": null,
            "upper_pattern": 579,
            "workbook": null,
            "mock": null
          }
        },
        "110": {
          "text": "[2] 미각 신경",
          "depth": 4,
          "obj": {
            "id": 672,
            "school_type": null,
            "school_year": null,
            "term": null,
            "problem_subject": null,
            "name": "[2] 미각 신경",
            "workbook_number": null,
            "update_dt": "2023-07-05T14:13:53.586244",
            "concept_vid_url": null,
            "upper_pattern": 579,
            "workbook": null,
            "mock": null
          }
        },
        "111": {
          "text": "[1] 신경계와 뉴런",
          "depth": 4,
          "obj": {
            "id": 675,
            "school_type": null,
            "school_year": null,
            "term": null,
            "problem_subject": null,
            "name": "[1] 신경계와 뉴런",
            "workbook_number": null,
            "update_dt": "2023-05-31T11:03:38.253954",
            "concept_vid_url": null,
            "upper_pattern": 581,
            "workbook": null,
            "mock": null
          }
        },
        "112": {
          "text": "[2] 중추 신경계",
          "depth": 4,
          "obj": {
            "id": 676,
            "school_type": null,
            "school_year": null,
            "term": null,
            "problem_subject": null,
            "name": "[2] 중추 신경계",
            "workbook_number": null,
            "update_dt": "2023-05-31T11:03:45.694721",
            "concept_vid_url": null,
            "upper_pattern": 581,
            "workbook": null,
            "mock": null
          }
        },
        "113": {
          "text": "[3] 말초 신경계",
          "depth": 4,
          "obj": {
            "id": 677,
            "school_type": null,
            "school_year": null,
            "term": null,
            "problem_subject": null,
            "name": "[3] 말초 신경계",
            "workbook_number": null,
            "update_dt": "2023-05-31T11:03:53.301622",
            "concept_vid_url": null,
            "upper_pattern": 581,
            "workbook": null,
            "mock": null
          }
        },
        "114": {
          "text": "[1] 의식적 반응과 무조건 반사",
          "depth": 4,
          "obj": {
            "id": 678,
            "school_type": null,
            "school_year": null,
            "term": null,
            "problem_subject": null,
            "name": "[1] 의식적 반응과 무조건 반사",
            "workbook_number": null,
            "update_dt": "2024-06-10T13:12:01.229199",
            "concept_vid_url": null,
            "upper_pattern": 582,
            "workbook": null,
            "mock": null
          }
        },
        "115": {
          "text": "[2] 반응 경로 비교",
          "depth": 4,
          "obj": {
            "id": 679,
            "school_type": null,
            "school_year": null,
            "term": null,
            "problem_subject": null,
            "name": "[2] 반응 경로 비교",
            "workbook_number": null,
            "update_dt": "2023-05-31T11:04:22.618847",
            "concept_vid_url": null,
            "upper_pattern": 582,
            "workbook": null,
            "mock": null
          }
        },
        "116": {
          "text": "[1] 호르몬의 정의",
          "depth": 4,
          "obj": {
            "id": 680,
            "school_type": null,
            "school_year": null,
            "term": null,
            "problem_subject": null,
            "name": "[1] 호르몬의 정의",
            "workbook_number": null,
            "update_dt": "2023-05-31T11:04:33.693669",
            "concept_vid_url": null,
            "upper_pattern": 583,
            "workbook": null,
            "mock": null
          }
        },
        "117": {
          "text": "[2] 내분비샘과 호르몬",
          "depth": 4,
          "obj": {
            "id": 681,
            "school_type": null,
            "school_year": null,
            "term": null,
            "problem_subject": null,
            "name": "[2] 내분비샘과 호르몬",
            "workbook_number": null,
            "update_dt": "2023-05-31T11:04:41.950741",
            "concept_vid_url": null,
            "upper_pattern": 583,
            "workbook": null,
            "mock": null
          }
        },
        "118": {
          "text": "[3] 호르몬 관련 질병",
          "depth": 4,
          "obj": {
            "id": 682,
            "school_type": null,
            "school_year": null,
            "term": null,
            "problem_subject": null,
            "name": "[3] 호르몬 관련 질병",
            "workbook_number": null,
            "update_dt": "2023-05-31T11:04:50.795615",
            "concept_vid_url": null,
            "upper_pattern": 583,
            "workbook": null,
            "mock": null
          }
        },
        "119": {
          "text": "[1] 체온 조절 과정",
          "depth": 4,
          "obj": {
            "id": 683,
            "school_type": null,
            "school_year": null,
            "term": null,
            "problem_subject": null,
            "name": "[1] 체온 조절 과정",
            "workbook_number": null,
            "update_dt": "2023-05-31T11:05:03.561113",
            "concept_vid_url": null,
            "upper_pattern": 584,
            "workbook": null,
            "mock": null
          }
        },
        "120": {
          "text": "[2] 혈당량 조절 과정",
          "depth": 4,
          "obj": {
            "id": 684,
            "school_type": null,
            "school_year": null,
            "term": null,
            "problem_subject": null,
            "name": "[2] 혈당량 조절 과정",
            "workbook_number": null,
            "update_dt": "2023-05-31T11:05:15.993954",
            "concept_vid_url": null,
            "upper_pattern": 584,
            "workbook": null,
            "mock": null
          }
        }
      },
    };
  },
  mounted() {
    this.initTreeNodesCheckedByQuery();
  },
  methods: {
    initTreeNodesCheckedByQuery() {
      const query = this.$route.query;
      const checkedId = query?.checkedId ?? null;
      if (!checkedId) return;

      const treeNodes = this.treeNodes;
      const entries = Object.entries(treeNodes);
      for (let index = 0; index < entries.length; index++) {
        const entry = entries[index];
        const key = entry[0];
        const obj = entry[1];
        if (obj.obj.id == checkedId) {
          obj.state.checked = true;

          let upList = obj.children ?? [];
          let childrenList = [];
          while (upList.length > 0) {
            for (let upIdx = 0; upIdx < upList.length; upIdx++) {
              const resList = this.checkTreeNodeById(upList[upIdx]);
              childrenList.push(...resList);
            }
            upList = [...childrenList];
            childrenList = [];
          }
        }
      }
    },
    checkTreeNodeById(id) {
      const treeNodes = this.treeNodes;
      const entries = Object.entries(treeNodes);
      for (let index = 0; index < entries.length; index++) {
        const entry = entries[index];
        const key = entry[0];
        const obj = entry[1];
        if (key == id) {
          obj.state.checked = true;
          return obj.children ?? [];
        }
      }
      return [];
    },
    onChangeSettingItem(midIndex, subIndex, isSelect) {
      this.changeSettingItemSelect({
        mainIndex: this.selectTabIndex,
        midIndex: midIndex,
        subIndex: subIndex,
        isSelect: isSelect,
      });
    },
    ...mapActions('settings', ['changeSettingItemSelect']),
  },
  computed: {
    settingItems() {
      return this.selectTabIndex === 0 ? this.getSettingDiag : this.getSettingUnit;
    },
    ...mapGetters('settings', ['getSettingDiag', 'getSettingUnit']),
  },
}
</script>
