<template>
  <div class="error-msg-box-wrap">
    <div class="v2-base-container">
      <img src="@/assets/images/min-window-info-icon.png" alt=""/>
      <h1>{{ this.headerTitle }}</h1>
      <p>{{ this.contents }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ErrorMsgBox',
  props: {
    headerTitle: { type: String, default: '오류 안내' },
    contents: { type: String, default: '오류가 발생하였습니다.' },
  },
}
</script>

<style scoped lang="scss">
.error-msg-box-wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  .v2-base-container {
    flex-direction: column;
    align-items: center;
    &>img {
      width: 26px;
      object-fit: contain;
    }
    &>h1 {
      width: 100%;
      margin-top: 15px;
      font-size: 20px;
      font-weight: 500;
      text-align: center;
    }
    &>p {
      margin-top: 10px;
      font-size: 16px;
      color: rgb(112, 112, 112);
      text-align: center;
      word-break: keep-all;
    }
  }
}
</style>
