import api from "./api"

class StudentWorkService {
  createStudentWork(payload) {
    return api.post('/view_set/student_work/', payload, {
      headers: { "Content-Type": "multipart/form-data" },
    })
  }
  
  getStudentWorks(params) {
    return api.get(`/view_set/student_work/`, { params })
  }

  getStudentWorksByHistory(student_id, params) {
    return api.get(`/problem/student_problem/${student_id}/`, { params })
  }
  
  getStudentWork(id, params) {
    return api.get(`/view_set/student_work/${id}/`, { params })
  }
  
  getStudentWorkProblems(params) {
    return api.get(`/view_set/student_work_problem/`, { params })
  }

  updateStudentWorkProblem(studentWorkProblemId, payload) {
    return api.patch(`/view_set/student_work_problem/${studentWorkProblemId}/`, payload, {
      headers: { "Content-Type": "multipart/form-data" },
    })
  }

  getPreviewData(studentWorkId) {
    return api.get(`/student_work/preview/data/${studentWorkId}/`, {});
  }

  /**
   * @param {number} studentWorkProblemId
   * @param {string} answer
   * @param {boolean} isPass
   */
  submitAnswer(
      studentWorkProblemId,
      answer,
      isPass,
  ) {
    const payload = {
      user_answer: answer,
      is_pass: isPass,
    };
    return api.post(`/student_work/submit/answer/${studentWorkProblemId}/`, payload, {
      headers: { "Content-Type": "multipart/form-data" },
    })
  }

  getResult(studentWorkId) {
    return api.get(`/student_work/result/${studentWorkId}`, {});
  }
}

export default new StudentWorkService()
