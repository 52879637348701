export function getWeekNumberOfMonth(date) {
    const startWeekDayIndex = 0; // 1 MonthDay 0 Sundays
    const firstDate = new Date(date.getFullYear(), date.getMonth(), 1);
    const firstDay = firstDate.getDay();

    let weekNumber = Math.ceil((date.getDate() + firstDay) / 7);
    if (startWeekDayIndex === 1) {
        if (date.getDay() === 0 && date.getDate() > 1) {
            weekNumber -= 1;
        }

        if (firstDate.getDate() === 1 && firstDay === 0 && date.getDate() > 1) {
            weekNumber += 1;
        }
    }
    return weekNumber;
}

export function getWeekFirstDateByDt(date) { // 해당 주 일요일
    const targetDt = new Date(date.getTime());
    targetDt.setHours(0);
    targetDt.setMinutes(0);
    targetDt.setSeconds(0);
    targetDt.setMilliseconds(0);
    targetDt.setDate(targetDt.getDate() - targetDt.getDay());
    return targetDt;
}

export function getWeekLastDateByDt(date) { // 해당 주 토요일
    const targetDt = new Date(date.getTime());
    targetDt.setHours(23);
    targetDt.setMinutes(59);
    targetDt.setSeconds(59);
    targetDt.setMilliseconds(999);
    targetDt.setDate(targetDt.getDate() + (6 - targetDt.getDay()));
    return targetDt;
}

export function getWeekDateRangeByDt(date, subtractDate = 0) { // 해당 주 일요일 ~ 토요일
    const targetDt = new Date(date.getTime());
    targetDt.setDate(targetDt.getDate() - subtractDate);
    return {
        'startTime': getWeekFirstDateByDt(targetDt).getTime(),
        'endTime': getWeekLastDateByDt(targetDt).getTime(),
    };
}

export function parseYmdHms(date) {
    const targetDt = new Date(date.getTime());
    return targetDt.getFullYear().toString()
        + '-'
        + (targetDt.getMonth() + 1).toString().padStart(2, '0')
        + '-'
        + targetDt.getDate().toString().padStart(2, '0')
        + ' '
        + targetDt.getHours().toString().padStart(2, '0')
        + ':'
        + targetDt.getMinutes().toString().padStart(2, '0')
        + ':'
        + targetDt.getSeconds().toString().padStart(2, '0')
    ;
}

export function parseYmd(date) {
    const targetDt = new Date(date.getTime());
    return targetDt.getFullYear().toString()
        + '-'
        + (targetDt.getMonth() + 1).toString().padStart(2, '0')
        + '-'
        + targetDt.getDate().toString().padStart(2, '0')
        ;
}

export function parseMonthWeek(date) {
    const targetDt = new Date(date.getTime());
    return(targetDt.getMonth() + 1).toString()
        + '월 '
        + getWeekNumberOfMonth(targetDt)
        + '주차'
    ;
}

export function getFirstDateByYear(date) { // 해당 주 일요일
    const targetDt = new Date(date.getTime());
    targetDt.setMonth(0);
    targetDt.setDate(1);
    targetDt.setHours(0);
    targetDt.setMinutes(0);
    targetDt.setSeconds(0);
    targetDt.setMilliseconds(0);
    return targetDt;
}
