import { createStore } from "vuex"
import { auth } from "./auth.module"
import {settings} from "@/store/settings.module";
import {learningHistoryDate} from "@/store/learning_history_date.module";
import {conceptVidItemStore} from "@/store/concept_vid_url_store.module";

const store = createStore({
  modules: {
    auth,
    settings,
    learningHistoryDate,
    conceptVidItemStore,
  },
})

export default store
