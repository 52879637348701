<template>
  <div class="student-learning-history-wrap">
    <V2Wrapper>
      <div class="slhw-inner">
        <div class="v2-base-container">
          <div class="wi-week-learning-contents-wrap">
            <h1>이번 주 학습 내용</h1>
            <div class="wlc-list-wrap">
              <div class="cl-item">
                <h5>전체 문항</h5>
                <p>{{ this.thisWeekTotalProblemCntStr }}</p>
              </div>
              <div class="cl-item">
                <h5>정답률</h5>
                <p>{{ this.thisWeekRateStr }}</p>
              </div>
            </div>
          </div>
          <div class="wi-recent-four-week-wrap">
            <h1>최근 4주간 학습 내용</h1>
            <div class="rfw-chart-wrap">
              <LineChart
                :chartData="this.recent4WeeksChartData"
                :options="this.recent4WeeksChartOption"
                :style="[{'max-height': '100%', 'aspect-ratio': '2.5'}]"
              />
            </div>
          </div>
          <div class="wi-period-history-wrap">
            <div class="ph-date-wrap">
              <div class="phd-inner-wrap">
                <input ref="periodDatePicker"
                       @change="(event) => this.onPeriodDatePickerChangeEvent(event)"
                >
                <p>기간별 학습 기록</p>
                <span @click="() => this.$refs.periodDatePicker.click()">{{ this.periodRangeDtStr }}</span>
                <div class="hdw-cal-btn"
                     @click="() => this.$refs.periodDatePicker.click()"
                >
                  <img src="@/assets/images/calendar.png" alt="">
                </div>
              </div>
            </div>
            <div class="ph-tab-list-wrap">
              <template v-for="(tabItem, tabIndex) in this.periodTabList" :key="tabIndex">
                <div :class="['tl-item', {'active': this.selectPeriodTabIndex === tabIndex}]"
                     @click="() => this.onChangeSelectTabIndexEvent(tabIndex)"
                >
                  <p>{{ tabItem }}</p>
                </div>
              </template>
            </div>
            <div class="ph-tab-body-wrap">
              <div class="tb-header-wrap">
                <p>학습 현황</p>
              </div>
              <div class="tb-sub-header-wrap">
                <template v-for="(item, itemIndex) in this.periodSubHeaderItems" :key="itemIndex">
                  <div class="sh-item">
                    <p>{{ item.title }} :&nbsp;</p>
                    <span>{{ item.contents }}</span>
                  </div>
                </template>
              </div>
              <div class="tb-contents-wrap">
                <template v-for="(dataItem, dataIndex) in this.periodDataList" :key="dataIndex">
                  <template v-if="dataIndex > 0">
                    <div class="bc-divider">
                      <div></div>
                    </div>
                  </template>
                  <div class="bc-item"
                       @click="() => this.$router.push({ name: 'StudentLearningHistoryDetail', params: {studentWorkId: dataItem.id}, })"
                  >
                    <div class="bci-header-wrap">
                      <p>[{{ dataItem.type }}] {{ dataItem.name }}</p>
                      <span>{{ dataItem.state }}</span>
                    </div>
                    <div class="bci-body-wrap">
                      <p>{{ dataItem.name }}</p>
                      <span>{{ dataItem.scoreStr }}</span>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </V2Wrapper>
  </div>
</template>

<script>
import V2Wrapper from "@/components/V2Wrapper.vue";
import {mapActions, mapGetters} from "vuex";
import StudentWorkService from "@/services/studentWork.service";
import utils from "@/utils";
import {StudentWorkByHistoryVo} from "@/models/student_work_by_history_vo";
import {LineChart} from "vue-chart-3";
import {StudentWorkTypeExt} from "@/extensions/student_work_type_ext";

export default {
  name: 'StudentLearningHistory',
  components: {LineChart, V2Wrapper},
  data() {
    return {
      academyUserId: null,

      /** @type {StudentWorkByHistoryVo[]|null} */
      thisWeekDataList: null,
      /** @type {StudentWorkByHistoryVo[]|null} */
      last1WeekDataList: null,
      /** @type {StudentWorkByHistoryVo[]|null} */
      last2WeekDataList: null,
      /** @type {StudentWorkByHistoryVo[]|null} */
      last3WeekDataList: null,

      periodTabList: [
        '전체',
        '숙제',
        '학습지',
        '교재',
      ],
      selectPeriodTabIndex: 0,
      /** @type {StudentWorkByHistoryVo[]|null} */
      selectWeekDataList: null,
    };
  },
  mounted() {
    this.initDatePicker();

    this.initLearningHistoryDate();
    this.academyUserId = this.getStudentAcademyUserId;

    this.initData();
  },
  methods: {
    async refreshSelectWeekDataList() {
      this.selectWeekDataList = await this.getWeekRangeData(this.getSelectWeekTimeRangeStartDt, this.getSelectWeekTimeRangeEndDt);
    },
    initData() {
      this.refreshSelectWeekDataList();
      this.getWeekRangeData(this.getThisWeekTimeRangeStartDt, this.getThisWeekTimeRangeEndDt).then(res => {
        this.thisWeekDataList = res;
      });
      this.getWeekRangeData(this.getLast1WeekTimeRangeStartDt, this.getLast1WeekTimeRangeEndDt).then(res => {
        this.last1WeekDataList = res;
      });
      this.getWeekRangeData(this.getLast2WeekTimeRangeStartDt, this.getLast2WeekTimeRangeEndDt).then(res => {
        this.last2WeekDataList = res;
      });
      this.getWeekRangeData(this.getLast3WeekTimeRangeStartDt, this.getLast3WeekTimeRangeEndDt).then(res => {
        this.last3WeekDataList = res;
      });
    },
    async initDatePicker() {
      await this.$loadScript('https://cdn.jsdelivr.net/jquery/latest/jquery.min.js');
      await this.$loadScript('https://cdn.jsdelivr.net/momentjs/latest/moment.min.js');
      await this.$loadScript('https://cdn.jsdelivr.net/npm/daterangepicker/daterangepicker.min.js');

      const nowDt = new Date(utils.parseYmd(new Date()));
      const minDt = new Date(utils.getFirstDateByYear(nowDt));
      minDt.setFullYear(minDt.getFullYear() - 1);

      const selectWeekTimeRangeStartDt = this.getSelectWeekTimeRangeStartDt;
      const selectWeekTimeRangeEndDt = this.getSelectWeekTimeRangeEndDt;

      const pickerEl = this.$refs.periodDatePicker;
      const pickerSel = $(pickerEl);
      pickerSel.daterangepicker({
        startDate: selectWeekTimeRangeStartDt,
        endDate: selectWeekTimeRangeEndDt,
        singleDatePicker: true,
        showDropdowns: true,
        minDate: minDt,
        maxDate: nowDt,
        autoApply: true,
        locale: {
          format: "YYYY-MM-DD",
          monthNames: ["1월", "2월", "3월", "4월", "5월", "6월", "7월", "8월", "9월", "10월", "11월", "12월"],
        },
      });

      function refreshPickerSelectWeekStyle(picker) {
        const selectDt = new Date(picker.startDate.format('YYYY-MM-DD'));
        const changeStartDt = utils.getWeekFirstDateByDt(selectDt);
        const changeEndDt = utils.getWeekLastDateByDt(selectDt);
        picker.setStartDate(changeStartDt);
        picker.setEndDate(changeEndDt);
      }

      pickerSel.on('show.daterangepicker', function(ev, picker) {
        let activeCell = picker.container[0].querySelector('td.start-date');
        activeCell.parentElement.classList.add('active');
      });

      pickerSel.on('apply.daterangepicker', function(ev, picker) {
        pickerEl.dispatchEvent(new Event('change'));
        refreshPickerSelectWeekStyle(picker);
      });

      refreshPickerSelectWeekStyle(pickerSel.data('daterangepicker'));
    },
    onPeriodDatePickerChangeEvent() {
      const pickerEl = this.$refs.periodDatePicker;

      const selectDt = new Date(pickerEl.value);
      const changeStartDt = utils.getWeekFirstDateByDt(selectDt);
      const changeEndDt = utils.getWeekLastDateByDt(selectDt);

      this.setSelectWeekTimeRangeStartDt(changeStartDt);
      this.setSelectWeekTimeRangeEndDt(changeEndDt);

      this.refreshSelectWeekDataList();
    },
    async getWeekRangeData(startDt, endDt) {
      const res = await StudentWorkService.getStudentWorksByHistory(this.academyUserId, {
        'create_dt_start': utils.parseYmdHms(startDt),
        'create_dt_end': utils.parseYmdHms(endDt),
      });
      return (res ?? []).map(data => StudentWorkByHistoryVo.fromJson(data));
    },
    onChangeSelectTabIndexEvent(tabIndex) {
      this.selectPeriodTabIndex = tabIndex;
    },
    ...mapActions('learningHistoryDate', [
      'initLearningHistoryDate',
      'setSelectWeekTimeRangeStartDt',
      'setSelectWeekTimeRangeEndDt',
    ]),
  },
  computed: {
    thisWeekTotalProblemCntStr() {
      const thisWeekDataList = this.thisWeekDataList;
      if (!thisWeekDataList) return '';

      return `${StudentWorkByHistoryVo.getTotalAnswerProblemCntByList(thisWeekDataList)} 문제`;
    },
    thisWeekRateStr() {
      const thisWeekDataList = this.thisWeekDataList;
      if (!thisWeekDataList) return '';

      const totalAnswerProblemCnt = StudentWorkByHistoryVo.getTotalAnswerProblemCntByList(thisWeekDataList);
      if (!totalAnswerProblemCnt) return '0%';

      const totalCorrectProblemCnt = StudentWorkByHistoryVo.getTotalCorrectProblemCntByList(thisWeekDataList);

      return `${Math.floor(totalCorrectProblemCnt / totalAnswerProblemCnt * 1000) / 10}%`;
    },
    recent4WeeksChartData() {
      const thisWeekDataList = this.thisWeekDataList ?? [];
      const last1WeekDataList = this.last1WeekDataList ?? [];
      const last2WeekDataList = this.last2WeekDataList ?? [];
      const last3WeekDataList = this.last3WeekDataList ?? [];

      const last1WeekTimeRangeStartDt = this.getLast1WeekTimeRangeStartDt;
      const last2WeekTimeRangeStartDt = this.getLast2WeekTimeRangeStartDt;
      const last3WeekTimeRangeStartDt = this.getLast3WeekTimeRangeStartDt;

      return {
        labels: [
          utils.parseMonthWeek(last3WeekTimeRangeStartDt),
          utils.parseMonthWeek(last2WeekTimeRangeStartDt),
          utils.parseMonthWeek(last1WeekTimeRangeStartDt),
          '이번 주',
        ],
        datasets: [
          {
            label: '학습한 문제 수',
            data: [
              StudentWorkByHistoryVo.getTotalAnswerProblemCntByList(last3WeekDataList),
              StudentWorkByHistoryVo.getTotalAnswerProblemCntByList(last2WeekDataList),
              StudentWorkByHistoryVo.getTotalAnswerProblemCntByList(last1WeekDataList),
              StudentWorkByHistoryVo.getTotalAnswerProblemCntByList(thisWeekDataList),
            ],
            borderColor: '#008ED5',
            pointBackgroundColor: '#008ED5',
            fill: true,
            backgroundColor: '#A4E0FF',
          },
        ],
      };
    },
    recent4WeeksChartOption() {
      return {
        devicePixelRatio: 2,
        responsive: true,
        plugins: {
          legend: {
            display: false,
          },
          datalabels: {
            display: false,
          },
        },
        scales: {
          x: {
            display: true,
            color: '#ffffff',
          },
          y: {
            display: true,
            min: 0,
          },
        },
      };
    },
    periodRangeDtStr() {
      const selectWeekTimeRangeStartDt = this.getSelectWeekTimeRangeStartDt;
      const selectWeekTimeRangeEndDt = this.getSelectWeekTimeRangeEndDt;

      return utils.parseYmd(selectWeekTimeRangeStartDt) +
          ' ~ ' +
          utils.parseYmd(selectWeekTimeRangeEndDt)
          ;
    },
    homeworkDataItems() {
      return (this.selectWeekDataList ?? []).filter(data => {
        return data.type == StudentWorkTypeExt.homework;
      }).map(data => {
        return Object.assign({}, data);
      });
    },
    worksheetDataItems() {
      return (this.selectWeekDataList ?? []).filter(data => {
        return data.type == StudentWorkTypeExt.worksheet;
      }).map(data => {
        return Object.assign({}, data);
      });
    },
    workbookDataItems() {
      return (this.selectWeekDataList ?? []).filter(data => {
        return data.type == StudentWorkTypeExt.workbook;
      }).map(data => {
        return Object.assign({}, data);
      });
    },
    periodSubHeaderItems() {
      const resList = [];

      const selectPeriodTabIndex = this.selectPeriodTabIndex;
      const homeworkDataItems = this.homeworkDataItems;
      const worksheetDataItems = this.worksheetDataItems;
      const workbookDataItems = this.workbookDataItems;

      const homeworkItem = {title: '숙제', contents: `${StudentWorkByHistoryVo.getCompletedCntByList(homeworkDataItems)}/${homeworkDataItems.length}`};
      const worksheetItem = {title: '학습지', contents: `${StudentWorkByHistoryVo.getCompletedCntByList(worksheetDataItems)}/${worksheetDataItems.length}`};
      const workbookItem = {title: '교재', contents: `${StudentWorkByHistoryVo.getCompletedCntByList(workbookDataItems)}/${workbookDataItems.length}`};

      switch (selectPeriodTabIndex) {
        case 0: // 전체
          resList.push(homeworkItem);
          resList.push(worksheetItem);
          resList.push(workbookItem);
          break;
        case 1: // 숙제
          resList.push(homeworkItem);
          break;
        case 2: // 학습지
          resList.push(worksheetItem);
          break;
        case 3: // 교재
          resList.push(workbookItem);
          break;
      }

      return resList;
    },
    periodDataList() {
      let resList = [];

      const selectPeriodTabIndex = this.selectPeriodTabIndex;

      switch (selectPeriodTabIndex) {
        case 0: // 전체
          resList = (this.selectWeekDataList ?? []).map(data => {
            return Object.assign({}, data);
          });
          break;
        case 1: // 숙제
          resList = this.homeworkDataItems;
          break;
        case 2: // 학습지
          resList = this.worksheetDataItems;
          break;
        case 3: // 교재
          resList = this.workbookDataItems;
          break;
      }

      return resList;
    },
    ...mapGetters('auth', [
      'getUser',
      'getStudentAcademyUserId',
    ]),
    ...mapGetters('learningHistoryDate', [
      'getSelectWeekTimeRangeStartDt',
      'getSelectWeekTimeRangeEndDt',
      'getThisWeekTimeRangeStartDt',
      'getThisWeekTimeRangeEndDt',
      'getLast1WeekTimeRangeStartDt',
      'getLast1WeekTimeRangeEndDt',
      'getLast2WeekTimeRangeStartDt',
      'getLast2WeekTimeRangeEndDt',
      'getLast3WeekTimeRangeStartDt',
      'getLast3WeekTimeRangeEndDt',
    ]),
  },
}
</script>
