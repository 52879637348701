<template>
  <div class="student-answer-detail-wrap">
    <template v-if="this.errorMsg !== null">
      <ErrorMsgBox
        title="오류 안내"
        :contents="this.errorMsg"
      />
    </template>
    <template v-else-if="this.isLoading">
      <div class="dw-loading">
        <b-spinner
            style="width: 3rem; height: 3rem;"
            label="Spinning"
        ></b-spinner>
      </div>
    </template>
    <template v-else>
      <div class="dw-header-wrap">
        <div class="v2-base-container">
          <button class="hw-btn btn-back"
                  @click="() => this.handleBackPressed()"
          >
            <img src="@/assets/images/arrow-left-4.png" alt="">
          </button>
          <div class="hw-btn btn-back-empty"></div>
          <p>{{ this.headerTitle }}</p>
          <div class="hw-btn btn-empty"></div>
        </div>
      </div>
      <div class="dw-body-wrap">
        <div class="bw-header-wrap">
          <div class="v2-base-container">
            <div class="bwhw-inner-wrap">
              <p>{{ this.totalScoreTitle }}</p>
              <div class="chw-list">
                <div :class="['wl-item', {'active': this.showType == AnswerDetailShowTypeExt.onlyWrong}]"
                     @click="() => this.handleClickShowType(AnswerDetailShowTypeExt.onlyWrong)"
                >
                  <p>틀린문제만 보기</p>
                </div>
                <div :class="['wl-item', {'active': this.showType == AnswerDetailShowTypeExt.onlyAnswer}]"
                     @click="() => this.handleClickShowType(AnswerDetailShowTypeExt.onlyAnswer)"
                >
                  <p>정답만 보기</p>
                </div>
                <div :class="['wl-item', {'active': this.isShowSolutionAll}]"
                     @click="() => this.handleToggleShowSolution()"
                >
                  <p>해설보기</p>
                </div>
              </div>
              <p>{{ this.totalAnswerPercentTitle }}</p>
            </div>
          </div>
        </div>
        <div class="bw-body-wrap">
          <div class="bw-abs-wrap">
            <div class="v2-base-container">
              <div class="bwbw-inner-wrap">
                <template v-for="(studentWorkResultProblemVo, index) in this.showStudentWorkResultProblemVos" :key="index">
                  <div class="iw-item">
                    <h1 class="iwi-header">{{ studentWorkResultProblemVo.getNumber() }}번문제</h1>
                    <div class="iwi-sub-header-wrap">
                      <div class="hw-list">
                        <div>
                          <span>정답 :</span>
                          <span>{{ studentWorkResultProblemVo.getCleanProblemAnswer() }}</span>
                        </div>
                        <div>
                          <span>내가 고른 답 :</span>
                          <span>{{ studentWorkResultProblemVo.getCleanAnswer() }}</span>
                        </div>
                      </div>
                      <div class="hw-btn"
                           @click="() => studentWorkResultProblemVo.isShowSolution = !studentWorkResultProblemVo.isShowSolution"
                      >
                        <span>{{ studentWorkResultProblemVo.isShowSolution ? '해설닫기' : '해설보기' }}</span>
                      </div>
                    </div>
                    <div class="iwi-problem-wrap">
                      <img class="pw-p-img"
                           :src="studentWorkResultProblemVo.problemImg"
                           alt=""
                      >
                      <template v-if="studentWorkResultProblemVo.isCurrentAnswer == true">
                        <img class="pw-check-img" src="@/assets/images/result-current.png" alt="">
                      </template>
                      <template v-else-if="studentWorkResultProblemVo.isCurrentAnswer == false && studentWorkResultProblemVo.answer == ''">
                        <img class="pw-check-img" src="@/assets/images/result-pass.png" alt="">
                      </template>
                      <template v-else>
                        <img class="pw-check-img" src="@/assets/images/result-incurrent.png" alt="">
                      </template>
                    </div>
                    <template v-if="studentWorkResultProblemVo.isShowSolution">
                      <div class="iwi-solution-wrap">
                        <img :src="studentWorkResultProblemVo.problemSolutionImg" alt="">
                      </div>
                    </template>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import ErrorMsgBox from "@/components/ErrorMsgBox.vue";
import StudentWorkService from "@/services/studentWork.service";
import {StudentWorkResultVo} from "@/models/student_work_result_vo";
import {AnswerDetailShowTypeExt} from "@/extensions/answer_detail_show_type_ext";

export default {
  name: 'StudentAnswerDetail',
  components: {
    ErrorMsgBox,
  },
  props: {
    studentWorkId: String,
    headerTitle: String,
  },
  data() {
    return {
      /** @type {string|null} */
      errorMsg: null,
      /** @type {boolean} */
      isLoading: true,
      /** @type {string} */
      showType: AnswerDetailShowTypeExt.onlyWrong,
      /** @type {AnswerDetailShowTypeExt} */
      AnswerDetailShowTypeExt,
      /** @type {StudentWorkResultProblemVo[]} */
      studentWorkResultProblemVos: [],
    };
  },
  mounted() {
    this.initData();
  },
  methods: {
    async initData() {
      const studentWorkId = this.studentWorkId;
      if (!studentWorkId) {
        this.errorMsg = '숙제 ID 정보를 알 수 없습니다';
        return;
      }

      let res;
      try {
        res = await StudentWorkService.getResult(studentWorkId);
      } catch (e) {
        console.log(e);
        this.errorMsg = '결과 값을 받아오는 중 오류가 발생하였습니다';
        return;
      }
      const studentWorkResultVo = StudentWorkResultVo.fromJson(res);
      this.studentWorkResultProblemVos = studentWorkResultVo.studentWorkProblems;

      this.isLoading = false;
    },
    handleBackPressed() {
      if (history.length <= 1) {
        window.close();
      } else {
        window.history.back();
      }
    },
    handleClickShowType(showTypeExt) {
      const showType = this.showType;
      if (showType == showTypeExt) {
        this.showType = AnswerDetailShowTypeExt.all;
      } else {
        this.showType = showTypeExt;
      }
    },
    handleToggleShowSolution() {
      const isShowSolutionAll = this.isShowSolutionAll;
      this.studentWorkResultProblemVos.forEach(e => {
        e.isShowSolution = !isShowSolutionAll;
      });
    },
  },
  computed: {
    isShowSolutionAll() {
      return this.studentWorkResultProblemVos.findIndex(e => {
        return !e.isShowSolution;
      }) < 0;
    },
    totalScoreTitle() {
      const correctVos = this.studentWorkResultProblemVos.filter(e => {
        return e.isCurrentAnswer === true;
      });

      const sumScore = correctVos.reduce(
        (acc, cur) => {
          return acc + cur.problemScore;
        },
        0,
      );

      return `${sumScore}점`;
    },
    totalAnswerPercentTitle() {
      const studentWorkResultProblemVos = this.studentWorkResultProblemVos;
      const totalCnt = studentWorkResultProblemVos.length;
      const correctCnt = studentWorkResultProblemVos.filter(e => {
        return e.isCurrentAnswer === true;
      }).length;

      return `${correctCnt}/${totalCnt}`;
    },
    /**
     * @return {StudentWorkResultProblemVo[]}
     */
    showStudentWorkResultProblemVos() {
      return this.studentWorkResultProblemVos.filter(e => {
        switch (this.showType) {
          case AnswerDetailShowTypeExt.all:
            return true;
          case AnswerDetailShowTypeExt.onlyWrong:
            return e.isCurrentAnswer != false;
          case AnswerDetailShowTypeExt.onlyAnswer:
            return e.isCurrentAnswer != true;
        }
      });
    },
  },
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/values";

.student-answer-detail-wrap {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .dw-loading {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    color: #{$defaultColor};
  }
  .dw-header-wrap {
    width: 100%;
    background-color: #{$defaultColor};
    height: 60px;
    display: flex;
    @include tablet {
      height: 40px;
    }
    .v2-base-container {
      gap: 5px;
      align-items: center;
      .hw-btn {
        width: 70px;
        height: 100%;
        cursor: pointer;
        @include tablet {
          width: 50px;
        }
        &>img {
          width: 100%;
          height: 25px;
          object-fit: contain;
          object-position: 5px;
          @include tablet {
            height: 20px;
          }
        }
        &.btn-back {
          display: none;
          @include tablet {
            display: unset;
          }
        }
        &.btn-back-empty {
          cursor: unset;
          @include tablet {
            display: none;
          }
        }
        &.btn-empty {
          cursor: unset;
        }
      }
      &>p {
        flex: 1;
        text-align: center;
        font-weight: 700;
        font-size: 20px;
        color: white;
        @include tablet {
          font-size: 15px;
          padding-right: 47px;
        }
        @include mobile {
          padding-right: unset;
        }
      }
    }
  }
  .dw-body-wrap {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .bw-header-wrap {
      margin-top: 25px;
      width: 100%;
      display: flex;
      @include tablet {
        margin-top: 15px;
      }
      .v2-base-container {
        .bwhw-inner-wrap {
          width: 100%;
          max-width: 1000px;
          margin: 0 auto;
          display: flex;
          align-items: center;
          gap: 70px;
          @include mobile {
            flex-wrap: wrap;
            justify-content: space-between;
          }
          @include tablet {
            gap: 20px;
          }
          @include mobile {
            gap: 7px;
          }
          &>p {
            font-weight: 500;
            font-size: 24px;
            color: black;
            @include tablet {
              font-size: 16px;
            }
            @include mobile {
              font-size: 14px;
            }
            &:last-child {
              text-align: end;
            }
          }
          .chw-list {
            flex: 1;
            display: flex;
            border-top: 1px solid #dddddd;
            border-bottom: 1px solid #dddddd;
            background-color: white;
            padding: 15px 70px;
            gap: 20px;
            align-items: center;
            @include tablet {
              padding: 10px 30px;
            }
            @include mobile {
              min-width: 100%;
              order: 1;
              padding: 10px 5px;
              gap: 5px;
            }
            .wl-item {
              flex: 1;
              display: flex;
              justify-content: center;
              align-items: center;
              background-color: white;
              box-shadow: 0 0 2px 0 rgba(0, 0, 0, .25);
              border-radius: 15px;
              padding: 4px;
              cursor: pointer;
              @include mobile {
                padding: 7px 2px;
              }
              &>p {
                line-height: 1;
                font-weight: 500;
                font-size: 20px;
                text-align: center;
                color: black;
                @include tablet {
                  font-size: 14px;
                }
                @include mobile {
                  font-size: 12px;
                }
              }
              &.active {
                background-color: #{$defaultColor};
                &>p {
                  color: white;
                }
              }
            }
          }
        }
      }
    }
    .bw-body-wrap {
      width: 100%;
      flex: 1;
      position: relative;
      .bw-abs-wrap {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        overflow-y: auto;
        .v2-base-container {
          .bwbw-inner-wrap {
            width: 100%;
            max-width: 1000px;
            margin: 0 auto;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            flex-direction: column;
            padding: 25px 0;
            gap: 100px 50px;
            @include tablet {
              padding: 15px 0;
              gap: 50px 25px;
            }
            @include mobile {
              grid-template-columns: repeat(1, 1fr);
            }
            .iw-item {
              width: 100%;
              position: relative;
              display: flex;
              flex-direction: column;
              &:before {
                content: '';
                position: absolute;
                top: -51px;
                width: 100%;
                height: 2px;
                background-color: #cccccc;
                @include tablet {
                  top: -26px;
                  height: 1px;
                }
              }
              &:nth-child(1) {
                &:before {
                  display: none;
                }
              }
              &:nth-child(2) {
                &:before {
                  display: none;
                  @include mobile {
                    display: unset;
                  }
                }
              }
              .iwi-header {
                width: 100%;
                font-weight: 500;
                font-size: 16px;
                color: black;
                @include mobile {
                  font-size: 13px;
                }
              }
              .iwi-sub-header-wrap {
                margin-top: 25px;
                width: 100%;
                display: flex;
                align-items: center;
                @include tablet {
                  margin-top: 15px;
                }
                @include mobile {
                  margin-top: 10px;
                }
                .hw-list {
                  flex: 1;
                  display: flex;
                  gap: 40px;
                  align-items: center;
                  flex-wrap: wrap;
                  @include tablet {
                    gap: 15px;
                  }
                  &>div {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    @include tablet {
                      gap: 5px;
                    }
                    &>span {
                      font-weight: 500;
                      font-size: 16px;
                      color: black;
                      @include tablet {
                        font-size: 13px;
                      }
                      @include mobile {
                        font-size: 12px;
                      }
                    }
                  }
                }
                .hw-btn {
                  cursor: pointer;
                  &>span {
                    font-weight: 500;
                    font-size: 16px;
                    color: #{$defaultColor};
                    @include tablet {
                      font-size: 14px;
                    }
                    @include mobile {
                      font-size: 13px;
                    }
                  }
                }
              }
              .iwi-problem-wrap {
                margin-top: 10px;
                width: 100%;
                border-radius: 5px;
                background-color: white;
                box-shadow: 0 0 2px 0 rgba(0, 0, 0, .25);
                padding: 35px 15px;
                position: relative;
                @include tablet {
                  padding: 15px 10px;
                }
                .pw-p-img {
                  width: 100%;
                  object-fit: contain;
                }
                .pw-check-img {
                  position: absolute;
                  top: 0;
                  right: -15px;
                  width: calc(100% * .312);
                  object-fit: contain;
                }
              }
              .iwi-solution-wrap {
                margin-top: 15px;
                width: 100%;
                border-radius: 5px;
                background-color: white;
                box-shadow: 0 0 2px 0 rgba(0, 0, 0, .25);
                padding: 30px 15px;
                @include tablet {
                  padding: 15px 10px;
                }
                &>img {
                  width: 100%;
                  object-fit: contain;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
